import { useLocalStorage } from "@uidotdev/usehooks"
import Tabs from "antd/es/tabs"
import type { User as AuthUser } from "firebase/auth"
import { collection, limit, orderBy, query } from "firebase/firestore"
import { useCallback, useState } from "react"
import { useCollectionDataOnce } from "react-firebase-hooks/firestore"
import { useParams } from "react-router"

import Header from "../../components/Header"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import LiveBadge from "../../live/LiveBadge"
import LiveFeed from "../../live/LiveFeed"
import { DEFAULT_SETTINGS, type Settings } from "../../live/LiveSettingsButton"
import TranscriptFeed from "../../live/TranscriptFeed"
import { resetSimulatedLiveAssistedCall } from "../../live/api"
import { dismissCard, markCardSeen } from "../../live/cards"
import {
  LIVE_ASSISTED_CALLS_COLLECTION,
  LIVE_ASSISTED_CALLS_TRANSCRIPT_SEGMENTS_SUBCOLLECTION,
  LIVE_UI_CARDS_SUBCOLLECTION,
} from "../../live/db"
import type {
  LiveSessionConfig,
  LiveTranscriptSegment,
  LiveUICard,
  LiveUIEvent,
} from "../../live/types"
import {
  replaceOrAppendByKey,
  uniqueByKeyWithPriority,
  updateWhere,
} from "../../utils"
import "./Call.css"
import WebCallControls from "./CallControls"
import TranscriptSearch from "./TranscriptSearch"
import useTranscriptProcessor from "./useTranscriptProcessor"
import useTtsPlayback from "./useTtsPlayback"

interface Props {
  callOid: string
  isSimulation?: boolean
}

export const BrowserCall: React.FC<Props> = ({ callOid, isSimulation }) => {
  const { handleSuccess, handleError, messageApi } = useErrorPopup()
  const [callSettings, setCallSettings] = useLocalStorage<Settings>(
    "quilt__live__callSettings",
    DEFAULT_SETTINGS,
  )
  const { onEvent: onEventForTts } = useTtsPlayback(
    callSettings.enableResultTts,
  )

  // Fetch the transcript segments once from firestore
  const [
    dbTranscriptSegments,
    dbTranscriptSegmentsLoading,
    ,
    ,
    reloadDbTranscriptSegments,
  ] = useCollectionDataOnce(
    query(
      collection(
        db,
        LIVE_ASSISTED_CALLS_COLLECTION,
        callOid,
        LIVE_ASSISTED_CALLS_TRANSCRIPT_SEGMENTS_SUBCOLLECTION,
      ).withConverter(makeConverter<LiveTranscriptSegment>()),
      orderBy("created_at", "asc"),
      limit(5000),
    ),
  )

  // Get live transcript segments from ws
  const [liveSegments, setLiveSegments] = useState<LiveTranscriptSegment[]>([])

  const [liveUiCards, setLiveUiCards] = useState<LiveUICard[]>([])
  const [dbCards, dbCardsLoading, , , reloadDbCards] = useCollectionDataOnce(
    query(
      collection(
        db,
        LIVE_ASSISTED_CALLS_COLLECTION,
        callOid,
        LIVE_UI_CARDS_SUBCOLLECTION,
      ).withConverter(makeConverter<LiveUICard>()),
      orderBy("created_at", "asc"),
      limit(1000),
    ),
  )

  const onEvent = useCallback(
    (event: LiveUIEvent) => {
      if (event.kind === "TRANSCRIPT_SEGMENT_UPDATE") {
        setLiveSegments((prev) =>
          replaceOrAppendByKey(prev, event.segment, (e) => e.oid),
        )
      } else if (event.kind === "LIVE_UI_CARD_UPDATE") {
        setLiveUiCards((prevCards) =>
          replaceOrAppendByKey(prevCards, event.card, (e) => e.oid),
        )
      }
      onEventForTts(event)
    },
    [onEventForTts],
  )

  const onDismissCard = useCallback(
    async (card: LiveUICard) => {
      if (!dbCards) {
        return
      }
      try {
        // HACK: If the card is in the db list, it has to be compied to the list
        // before dismissing will work.
        const dbCard = dbCards.find((c) => c.oid === card.oid)
        if (dbCard) {
          setLiveUiCards((prev) =>
            replaceOrAppendByKey(prev, dbCard, (e) => e.oid),
          )
        }
        setLiveUiCards((prev) =>
          updateWhere(
            prev,
            (c) => ({ ...c, dismissed: true }),
            (c) => c.oid === card.oid,
          ),
        )
        await dismissCard(callOid, card)
      } catch (error) {
        handleError({ error, prefix: "Card was not dismissed" })
      }
    },
    [callOid, dbCards, handleError],
  )

  const onMarkCardSeen = useCallback(
    async (card: LiveUICard) => {
      if (!dbCards) {
        return
      }
      try {
        // HACK: If the card is in the db list, it has to be compied to the list
        // before dismissing will work.
        const dbCard = dbCards.find((c) => c.oid === card.oid)
        if (dbCard) {
          setLiveUiCards((prev) =>
            replaceOrAppendByKey(prev, dbCard, (e) => e.oid),
          )
        }
        setLiveUiCards((prev) =>
          updateWhere(
            prev,
            (c) => ({ ...c, seen: true }),
            (c) => c.oid === card.oid,
          ),
        )
        await markCardSeen(callOid, card)
      } catch (error) {
        console.error("Could not mark card seen", error)
        // Just ignore the error in the UI
      }
    },
    [callOid, dbCards],
  )

  const onError = useCallback(
    (error: Error, message: string) => {
      console.error("WebSocket error", error, message)
      handleError({ error, prefix: "Connection problem, retrying" })
    },
    [handleError],
  )

  // Merge live and db transcript segments
  const transcriptSegments = uniqueByKeyWithPriority(
    [...liveSegments, ...(dbTranscriptSegments ?? [])],
    (a) => a.oid,
    (a) => a.created_at.toMillis(),
  )

  const cards = uniqueByKeyWithPriority(
    [...liveUiCards, ...(dbCards ?? [])],
    (a) => a.oid,
    (a) => a.created_at.toMillis(),
  )

  const sessionConfig: LiveSessionConfig = {
    disabled_actors: callSettings.disabledActors,
    simulation: isSimulation
      ? { kinds: callSettings.simulationInputEvents || [] }
      : undefined,
    model_name: callSettings.modelName,
  }

  const { playState, start, stop, pause } = useTranscriptProcessor(
    callOid,
    onEvent,
    /*includeUserStream=*/ !isSimulation,
    callSettings.includeSystemAudio,
    sessionConfig,
    onError,
  )

  const onPause = useCallback(() => {
    pause()
    if (playState === "playing") {
      void messageApi.open({
        type: "info",
        content: "Muted audio",
      })
    }
  }, [playState, pause, messageApi])

  const onStart = useCallback(async () => {
    try {
      if (playState === "stopped") {
        void messageApi.open({ type: "info", content: "Starting call..." })
      }
      await start()
      if (playState === "paused") {
        handleSuccess("Unmuted audio")
      }
      if (playState === "stopped") {
        handleSuccess("Started!")
      }
    } catch (error) {
      if (error instanceof DOMException && error.name === "NotAllowedError") {
        void messageApi.info("Call cancelled")
      } else if (error instanceof Error) {
        handleError({
          error,
          message: `Could not start call: ${error.message}`,
        })
      } else {
        handleError({ error, prefix: "Could not start call" })
      }
    }
  }, [playState, start, handleSuccess, handleError, messageApi])

  const onChangeSettings = useCallback(
    (settings: Partial<Settings>) =>
      setCallSettings((prevSettings) => ({ ...prevSettings, ...settings })),
    [setCallSettings],
  )

  const onResetSimulation = useCallback(async () => {
    setLiveSegments([])
    setLiveUiCards([])
    await resetSimulatedLiveAssistedCall(callOid)
    await Promise.all([reloadDbCards(), reloadDbTranscriptSegments()])
  }, [callOid, reloadDbCards, reloadDbTranscriptSegments])

  const isPlaying = playState === "playing"

  return (
    <div className="flex w-full grow flex-col overflow-y-hidden p-2 sm:p-4">
      <div className="flex w-full items-center sm:block">
        <div className="mr-2 flex flex-row sm:mb-2">
          <LiveBadge isLive={isPlaying} />
          <div className="text-md ml-2 hidden items-center font-semibold text-gray-600 sm:flex">
            Control the call here. Questions and answers will appear below.
          </div>
        </div>
        <WebCallControls
          settings={callSettings}
          onChangeSettings={onChangeSettings}
          playState={playState}
          onStart={onStart}
          onStop={stop}
          onPause={onPause}
          isSimulation={isSimulation}
          onResetSimulation={onResetSimulation}
        />
      </div>
      <div className="mt-6 border-b border-gray-50 sm:hidden"></div>
      <div className="flex grow overflow-y-hidden">
        <LiveFeed
          className="min-h-0 sm:min-w-[480px] sm:max-w-md"
          cards={cards}
          loading={dbCardsLoading}
          onDismissCard={onDismissCard}
          onMarkCardSeen={onMarkCardSeen}
        />
        <Tabs
          className="live-assistant-call-tabs ml-8 hidden min-h-0 w-full flex-col lg:flex"
          type="card"
          tabBarStyle={{ margin: 0 }}
          items={[
            {
              label: "Transcript",
              key: "transcript",
              children: (
                <TranscriptFeed
                  segments={transcriptSegments}
                  loading={dbTranscriptSegmentsLoading}
                  autoScroll
                />
              ),
            },
            {
              label: "Search Transcript",
              key: "search",
              children: <TranscriptSearch callOid={callOid} />,
            },
          ]}
        />
      </div>
    </div>
  )
}

const WebLiveAssistedCallPage: React.FC<{ user: AuthUser }> = () => {
  const { callOid = "" } = useParams<{ callOid: string }>()
  return (
    <>
      <Header
        title=""
        breadcrumbs={[
          { title: "Live Assistant", href: "/live-assistant" },
          { title: `Call ${callOid}`, href: `/live-assistant/${callOid}` },
        ]}
        className="max-sm:hidden"
      />
      <BrowserCall callOid={callOid} />
    </>
  )
}

export default WebLiveAssistedCallPage

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model"
import type {
  CellClassParams,
  CellFocusedEvent,
  CellValueChangedEvent,
  ColDef,
  Column,
  ColumnResizedEvent,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  ModelUpdatedEvent,
  SizeColumnsToFitGridStrategy,
  ValueGetterParams,
  ValueSetterParams,
} from "@ag-grid-community/core"
import { AgGridReact } from "@ag-grid-community/react"
import "@ag-grid-community/styles/ag-grid.css"
import "@ag-grid-community/styles/ag-theme-balham.css"
import Radio from "antd/es/radio"
import { useCallback, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useDebounceCallback, useLocalStorage } from "usehooks-ts"

import { getNonemptyCols } from "../../sheets/formatGrid"
import { sortStringsByLength } from "../../utils"
import LoadingSpinner from "../LoadingSpinner"
import CellWithTooltip from "./CellWithTooltip"
import { useGridContext } from "./GridContext"
import "./QuestionnaireReviewSheet.css"
import Tooltip from "./Tooltip"
import { focusCell } from "./focusCell"
import type { GridContext, RowData } from "./types"

interface QuestionnaireReviewSheetProps {
  jobOid: string
  sheetNames: string[]
  grid: RowData[]
  selectedSheet: string
  onCellValueEdited: (e: CellValueChangedEvent<RowData>) => void
  changeToSheet: (sheetName: string) => void

  onSaveToKnowledgeBase: (rowIndex: number) => void
  sidebarOpen: boolean
  openSidebar: () => void
}

const AUTO_SIZE_STRATEGY: SizeColumnsToFitGridStrategy = {
  type: "fitGridWidth",
}

const valueGetter = ({ data, colDef }: ValueGetterParams<RowData>) => {
  const rowData = data?.[colDef.field!]
  return rowData?.rawContent ?? ""
}

const valueSetter = ({
  data,
  colDef,
  newValue,
}: ValueSetterParams<RowData>) => {
  const newRawContent = String(newValue ?? "")
  const f = colDef.field!
  if (data[f]) {
    data[f].rawContent = newRawContent
  } else {
    data[f] = { rawContent: newRawContent }
  }
  return true
}

const cellClassRules = {
  "answer-cell-highlight--unreviewed": ({
    data,
    colDef,
  }: CellClassParams<RowData>) => {
    const answer = data?.[colDef.field!]?.answer
    return !!answer && !answer.last_reviewed_by && answer.confidence !== 0
  },
  "answer-cell-highlight--low-confidence": ({
    data,
    colDef,
  }: CellClassParams<RowData>) => {
    const answer = data?.[colDef.field!]?.answer
    return !!answer && !answer.last_reviewed_by && answer.confidence === 0
  },
  "answer-cell-highlight--reviewed": ({
    data,
    colDef,
  }: CellClassParams<RowData>) => {
    const answer = data?.[colDef.field!]?.answer
    return !!answer && !!answer.last_reviewed_by
  },
  "normal-cell-highlight": ({ data, colDef }: CellClassParams<RowData>) => {
    const answer = data?.[colDef.field!]?.answer
    return !answer
  },
}

const getRowId = (params: GetRowIdParams<RowData>): string => {
  return params.data.rowId
}

interface SheetUIState {
  columnStates: {
    colId: string
    width?: number | undefined
  }[]
}
interface QuestionnaireAssistantUIState {
  [jobOid: string]: {
    [sheetName: string]: SheetUIState
  }
}

const QuestionnaireReviewSheet: React.FC<QuestionnaireReviewSheetProps> = ({
  jobOid,
  sheetNames,
  grid,
  selectedSheet,
  onCellValueEdited,
  changeToSheet,
  onSaveToKnowledgeBase,
  sidebarOpen,
  openSidebar,
}) => {
  const { setCellFocused, setGridApi, gridApi, cellFocused } = useGridContext()

  const [searchParams] = useSearchParams()
  const urlSheetName = searchParams.get("sheetName")
  const firstRowIndex = searchParams.get("firstRowIndex")
  const firstColIndex = searchParams.get("firstColIndex")
  const [gridLoading, setGridLoading] = useState(!!urlSheetName)
  const [autoFocused, setAutoFocused] = useState(false)
  const [savedUIState, setSavedUIState] =
    useLocalStorage<QuestionnaireAssistantUIState>(
      "quilt__QuestionnaireReview__uiState",
      {},
    )

  const debouncedSetSavedUIState = useDebounceCallback(
    (
      updateFn: (
        prev: QuestionnaireAssistantUIState,
      ) => QuestionnaireAssistantUIState,
    ) => {
      setSavedUIState(updateFn)
    },
    300,
  )

  const onCellFocused = useCallback(
    (e: CellFocusedEvent) => {
      if (e.rowIndex !== null) {
        setCellFocused({
          rowIndex: e.rowIndex,
          colId: (e.column as Column).getColId(),
        })
      }
    },
    [setCellFocused],
  )

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      setGridApi(event.api)
    },
    [setGridApi],
  )

  const onColumnResized = useCallback(
    (event: ColumnResizedEvent<RowData, GridContext>) => {
      const { jobOid, sheetName: selectedSheet } = event.context
      if (!jobOid || !selectedSheet) {
        return
      }
      // TODO(mgraczyk): Discard old column state to limit local storage usage.
      debouncedSetSavedUIState((prev) => ({
        ...prev,
        [jobOid]: {
          ...prev[jobOid],
          [selectedSheet]: {
            columnStates: event.api
              .getColumnState()
              .map(({ colId, width }) => ({
                colId,
                width,
              })),
          },
        },
      }))
    },
    [debouncedSetSavedUIState],
  )

  const columnStates = savedUIState[jobOid]?.[selectedSheet]?.columnStates

  const columnKeys = useMemo(
    () => {
      // Sort so that columns are in the right order.
      // Also remove the special "rowId" column.
      return getNonemptyCols(grid)
        .sort(sortStringsByLength)
        .filter((col) => col !== "rowId")
    },
    // Do not recompute columnKeys when grid changes, since it is only used to compute columnDefs
    // However, it does depend on sheet.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSheet],
  )

  const columnDefs: ColDef[] = useMemo(() => {
    const widthsByKey = Object.fromEntries(
      (columnStates ?? []).map((state) => [state.colId, state.width]),
    )

    return [
      {
        headerName: "",
        valueGetter: ({ node }) => (node?.rowIndex ?? 0) + 1,
        minWidth: 48,
        maxWidth: 48,
        width: 48,
        pinned: "left",
        sortable: false,
        rowDrag: false,
        filter: false,
        cellClass: "select-none",
      },
      ...columnKeys.map((key) => {
        return {
          headerName: key,
          field: key,
          wrapText: true,
          autoHeight: true,
          editable: true,
          cellEditor: "agLargeTextCellEditor",
          width: widthsByKey[key],
          minWidth: 150,
          cellEditorPopup: false,
          sortable: false,
          rowDrag: false,
          filter: false,
          cellRenderer: CellWithTooltip,
          valueGetter: valueGetter,
          valueSetter: valueSetter,
          cellStyle: {
            wordBreak: "normal",
          },
          cellEditorParams: {
            maxLength: 5000,
          },
          cellClassRules,
          tooltipComponent: sidebarOpen ? null : Tooltip,
          // tooltipField is required to show the tooltip.
          tooltipField: sidebarOpen ? undefined : key,
        }
      }),
    ]
  }, [columnStates, columnKeys, sidebarOpen])

  const onAutoFocus = useCallback(
    (api: GridApi) => {
      if (!urlSheetName || !api || autoFocused) return

      changeToSheet(urlSheetName)

      if (firstRowIndex !== null && firstColIndex !== null) {
        // A slight delay to ensure that the scroll due to setFocusedCell is complete
        // Known issue: https://github.com/ag-grid/ag-grid/issues/6860
        setTimeout(() => {
          focusCell(Number(firstRowIndex) - 1, Number(firstColIndex), api)
          setGridLoading(false)
          setAutoFocused(true)
        }, 500)
      }

      openSidebar()
    },
    [
      urlSheetName,
      firstRowIndex,
      firstColIndex,
      openSidebar,
      changeToSheet,
      setAutoFocused,
      autoFocused,
    ],
  )

  // Only autofocus when rows auto height adjusted
  const onModelUpdated = useCallback(
    (e: ModelUpdatedEvent<RowData>) => e.keepRenderedRows && onAutoFocus(e.api),
    [onAutoFocus],
  )

  const gridContext = useMemo(
    () => ({
      jobOid,
      sheetName: selectedSheet,
      openSidebar,
      onSaveToKnowledgeBase,
    }),
    [jobOid, selectedSheet, openSidebar, onSaveToKnowledgeBase],
  )

  const onClipboardEvent = useCallback(
    async (event: React.ClipboardEvent) => {
      if (!gridApi || !cellFocused) {
        return
      }
      if (gridApi.getEditingCells().length > 0) {
        // Use the default behavior for copy/cut/paste when editing a cell.
        return
      }

      const selectedNode: IRowNode<RowData> | undefined = gridApi.getRowNode(
        `${selectedSheet}:${cellFocused.rowIndex}`,
      )
      if (!selectedNode || !selectedNode.data) {
        return
      }
      const column = gridApi.getColumn(cellFocused.colId)
      if (!column) {
        return
      }
      event.preventDefault()

      // Put data on clipboard.
      if (event.type === "copy" || event.type === "cut") {
        const value = selectedNode.data[cellFocused.colId]?.rawContent ?? ""
        // TODO(mgraczyk): Sometimes this doesn't work
        //  https://quilt-app.sentry.io/issues/5856213618
        // Possibly need to use document.execCommand('copy');
        await navigator.clipboard.writeText(value.toString())
      }

      // Write clipboard to cell.
      if (event.type === "cut" || event.type === "paste") {
        let newValue: string
        if (event.type === "cut") {
          newValue = ""
        } else {
          const { clipboardData } = event
          newValue = clipboardData.getData("Text") ?? ""
        }

        const gridEvent: CellValueChangedEvent<RowData> = {
          type: "cellValueChanged",
          newValue,
          rowIndex: cellFocused.rowIndex,
          value: newValue,
          column,
          node: selectedNode,
          rowPinned: null,
          colDef: column.getColDef() as ColDef<RowData>,
          context: gridContext,
          api: gridApi,
          data: selectedNode.data,
          source: undefined,
          oldValue: undefined,
        }

        // Set data so it shows up right away, then dispatch so that it updates in
        // the database.
        selectedNode.setData({
          ...selectedNode.data,
          [cellFocused.colId]: {
            ...selectedNode.data[cellFocused.colId],
            rawContent: newValue,
          },
        })
        gridApi.dispatchEvent(gridEvent)
      }
    },
    [selectedSheet, cellFocused, gridContext, gridApi],
  )

  // NOTE: This must match the width in QuestionnaireSidebar.tsx
  const widthWithSidebar = "w-[calc(100%-22rem)]"

  return (
    <div
      className={`flex flex-col ${sidebarOpen ? widthWithSidebar : "w-full pr-1"}`}
      onPaste={onClipboardEvent}
      onCopy={onClipboardEvent}
      onCut={onClipboardEvent}
    >
      {gridLoading && <LoadingSpinner />}
      <AgGridReact<RowData>
        autoSizeStrategy={AUTO_SIZE_STRATEGY}
        className={`quilt-questionnaire-editor ag-theme-balham grow transition-opacity ${gridLoading ? "opacity-0" : "opacity-100"} w-full`}
        columnDefs={columnDefs}
        context={gridContext}
        enableCellTextSelection
        enterNavigatesVertically
        enterNavigatesVerticallyAfterEdit
        getRowId={getRowId}
        onCellFocused={onCellFocused}
        onCellValueChanged={onCellValueEdited}
        onColumnResized={onColumnResized}
        onGridReady={onGridReady}
        onModelUpdated={onModelUpdated}
        // Set to a high number to avoid loading more rows when scrolling
        rowBuffer={9999}
        rowData={grid}
        stopEditingWhenCellsLoseFocus
        suppressMovableColumns
        undoRedoCellEditing
        undoRedoCellEditingLimit={20}
        modules={[ClientSideRowModelModule]}
        tooltipInteraction
        tooltipShowDelay={0}
        tooltipHideDelay={8000}
      />
      <Radio.Group
        rootClassName="overflow-x-scroll overflow-y-hidden flex w-full"
        value={selectedSheet}
        onChange={(e) => changeToSheet(String(e.target.value))}
        options={sheetNames}
        optionType="button"
        buttonStyle="solid"
        className="sheet-pages-selector"
      />
    </div>
  )
}

export default QuestionnaireReviewSheet

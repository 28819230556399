import { getInterpretableTimeString } from "../../timeUtils"
import UserAvatar from "../UserAvatar"
import type { AnswerWithDiscussion } from "./types"

interface FeedFooterBadgeProps {
  answer: AnswerWithDiscussion
  type: "last_reviewed_by" | "last_assigned_to"
}

export const FeedFooterBadge: React.FC<FeedFooterBadgeProps> = ({
  answer,
  type,
}) => {
  const userObj = answer[type]
  if (!userObj) return null
  return (
    <div className="mr-8">
      <div className="mb-2 font-bold">
        {type === "last_assigned_to" ? "Owner" : "Approved by"}
      </div>
      <div className="flex items-center">
        <UserAvatar name={userObj.email} size="xs" />
        <div className="ml-2">
          <div className="font-semibold">{userObj.email}</div>
          <div>{getInterpretableTimeString(userObj.created_at)}</div>
        </div>
      </div>
    </div>
  )
}

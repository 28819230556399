import Button from "antd/es/button"
import Tooltip from "antd/es/tooltip"
import { useCallback } from "react"

import LoadingSpinner from "../components/LoadingSpinner"
import { confirmAndExecute } from "../components/confirmAndExecute"

interface IntegrationBlockProps {
  name: string
  connected: boolean
  onConnect: () => void
  onDisconnect: () => void
  disconnectMessage?: string
  loading?: boolean
  disabledReason?: string
  logo?: string
  onEdit?: () => void
  notEnabled?: boolean
}

const IntegrationBlock: React.FC<IntegrationBlockProps> = ({
  name,
  connected,
  onConnect,
  onDisconnect,
  loading,
  disabledReason,
  notEnabled,
  logo,
  onEdit,
  disconnectMessage = "This will remove all synced documents.",
}) => {
  const onClickDisconnect = useCallback(() => {
    confirmAndExecute(
      onDisconnect,
      `Are you sure you want to disconnect ${name}? ${disconnectMessage}`,
    )()
  }, [onDisconnect, name, disconnectMessage])

  const editButtonJsx = !onEdit ? null : (
    <Button
      type="default"
      onClick={onEdit}
      disabled={loading || !connected || !!disabledReason || notEnabled}
    >
      Modify
    </Button>
  )
  const buttonJsx = (
    <Button
      type={connected ? "default" : "primary"}
      danger={connected}
      onClick={connected ? onClickDisconnect : onConnect}
      disabled={!!disabledReason || notEnabled}
    >
      {connected ? "Disconnect" : notEnabled ? "Contact Sales" : "Connect"}
    </Button>
  )

  return (
    <div className="flex w-full flex-row items-center justify-between rounded-md border p-4">
      <>
        <div className="flex items-center">
          {logo ? (
            <img src={logo} alt={`${name} logo`} className="mr-4 h-8 w-8" />
          ) : null}
          <div>{name}</div>
        </div>
        {loading ? (
          <LoadingSpinner className="mr-4" />
        ) : (
          <Tooltip
            title={
              notEnabled
                ? "Contact Quilt Sales to enable this integration, click the question mark on the left sidebar"
                : disabledReason
            }
          >
            <div className="flex gap-2">
              {connected ? editButtonJsx : null}
              {buttonJsx}
            </div>
          </Tooltip>
        )}
      </>
    </div>
  )
}

export default IntegrationBlock

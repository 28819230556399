import { useEffect, useState } from "react"

import { useKeydown } from "../hooks/events"
import useErrorPopup from "../hooks/useErrorPopup"
import { getSlackChannelsApi } from "../slack/api"
import type { SlackChannel, SlackInstallationMeta } from "../slack/apiTypes"
import SlackConfigurationForm from "./SlackConfigurationForm"

interface SlackConfigurationProps {
  onClose: () => void
  slackInstallation?: SlackInstallationMeta
  loading: boolean
  fetchSlackInstallation: () => Promise<void>
}
const SlackConfiguration: React.FC<SlackConfigurationProps> = ({
  onClose,
  loading,
  slackInstallation,
  fetchSlackInstallation,
}) => {
  const { handleError } = useErrorPopup()
  const [allSlackChannels, setAllSlackChannels] = useState<SlackChannel[]>([])
  const [channelsLoading, setChannelsLoading] = useState(false)

  useKeydown(
    27, // Escape
    onClose,
  )

  useEffect(() => {
    const fetchSlackChannels = async () => {
      setChannelsLoading(true)
      try {
        const { data } = await getSlackChannelsApi()
        setAllSlackChannels(data)
      } catch (error) {
        handleError({ prefix: "Unable to fetch Slack integration", error })
      } finally {
        setChannelsLoading(false)
      }
    }
    void fetchSlackChannels()
  }, [handleError])

  return (
    <SlackConfigurationForm
      slackInstallation={slackInstallation}
      accessibleSlackChannels={allSlackChannels}
      refetchSlackInstallation={fetchSlackInstallation}
      onClose={onClose}
      loading={channelsLoading || loading}
    />
  )
}
export default SlackConfiguration

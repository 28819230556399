import { useLocalStorage } from "@uidotdev/usehooks"
import Button from "antd/es/button"
import Dropdown from "antd/es/dropdown"
import Layout from "antd/es/layout"
import Menu from "antd/es/menu"
import type { User as AuthUser } from "firebase/auth"
import { MenuIcon } from "lucide-react"
import { Suspense, lazy, useMemo } from "react"
import { ErrorBoundary } from "react-error-boundary"
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"

import PlansPage from "../billing/PlansPage"
import QuiltLogo from "../components/QuiltLogo"
import { TourProvider } from "../components/Tour/TourContext"
import { EMPTY_ARRAY } from "../constants"
import { ActiveUserAuthorizationProvider } from "../contexts/ActiveUserAuthorizationContext"
import { ActiveGroupProvider, useActiveGroup } from "../contexts/useActiveGroup"
import type { QuiltFlags } from "../groups/types"
import KnowledgeItemPage from "../knowledge/KnowledgeItemPage"
import AssignmentsPage from "../pages/Assignments"
import ChatPage from "../pages/ChatPagev2"
import HomePage from "../pages/HomePage"
import KnowledgeItemsTable from "../pages/KnowledgeItemsTable"
import NotificationPreferences from "../pages/NotificationPreferences"
import NotificationsPage from "../pages/NotificationsPage"
import QuestionnaireAssistantPage from "../pages/QuestionnaireAssistant"
import ReferenceSearchPage from "../pages/ReferenceSearchPage"
import UserDocumentPage from "../pages/UserDocumentPage"
import UserDocumentsPage from "../pages/UserDocumentsPage"
import UserProfilePage from "../pages/UserProfilePage"
import StartWebLiveAssistantPage from "../pages/WebLiveAssistant"
import WebLiveAssistedCallPage from "../pages/WebLiveAssistant/Call"
import WebLiveAssistantSimulationPage from "../pages/WebLiveAssistant/Simulation"
import { TypesenseSearchProvider } from "../search/useTypesenseSearch"
import AcceptInvitePage from "./AcceptInvitePage"
import ComingSoonPage from "./ComingSoonPage"
import GroupSettingsPage from "./Groups/GroupSettingsPage"
import UserGroupsManager from "./Groups/UserGroupsManager"
import LoadingSpinner from "./LoadingSpinner"
import { getBottomNavItems, getNarrowNavItems, getNavItems } from "./NavMenus"
import NotFoundPage from "./NotFoundPage"
import QuestionnaireReview from "./QuestionnaireReview"
import QuestionnaireAnswer from "./QuestionnaireReview/QuestionnaireAnswer"
import QuestionnaireAnswers from "./QuestionnaireReview/QuestionnaireAnswers"
import SlackInstallError from "./SlackInstallError"
import SlackInstallSuccess from "./SlackInstallSuccess"
import SlackLinkAccount from "./SlackLinkAccount"
import SubscriptionExpiredBanner from "./SubscriptionExpiredBanner"
import "./TopLevelPageContainer.css"

const AdminPage = lazy(() => import("../quiltAdmin/AdminPage"))
const CrawlsPage = lazy(() => import("../crawls/WebsiteCrawlsTable"))
const LiveCoachingPage = lazy(() => import("../pages/LiveCoachingPage"))

const { Sider, Content } = Layout

const ErrorFallback: React.FC<{ error: Error }> = () => {
  // TODO(mgraczyk): Figure out how to reset boundary without breaking things.
  return (
    <div role="alert">
      <p>
        Something went wrong: Please refresh the page or contact support at
        support@quilt.app
      </p>
    </div>
  )
}

const logError = (error: Error, info: unknown) => {
  console.error("Error boundary", error, info)
}

const AppRoutes: React.FC<{ user: AuthUser; flags: QuiltFlags }> = ({
  user,
}) => {
  const LazyAdminPage = (
    <Suspense fallback={<LoadingSpinner />}>
      <AdminPage user={user} />
    </Suspense>
  )

  const LazyCrawlsPage = (
    <Suspense fallback={<LoadingSpinner />}>
      <CrawlsPage user={user} />
    </Suspense>
  )

  const LazyLiveCoachingPage = (
    <Suspense fallback={<LoadingSpinner />}>
      <LiveCoachingPage user={user} />
    </Suspense>
  )

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/source-documents"
        element={<UserDocumentsPage user={user} />}
      />
      <Route path="/source-documents/:docOid" element={<UserDocumentPage />} />
      <Route path="/website-crawls" element={LazyCrawlsPage} />
      <Route
        path="/questionnaire-assistant"
        element={<QuestionnaireAssistantPage user={user} />}
      />
      <Route
        path="/questionnaire-assistant/review/:oid"
        element={<QuestionnaireReview user={user} />}
      />
      <Route
        path="/questionnaire-assistant/review/:questionnaireOid/responses"
        element={<QuestionnaireAnswers />}
      />
      <Route
        path="/questionnaire-assistant/review/:questionnaireOid/responses/:answerOid"
        element={<QuestionnaireAnswer />}
      />
      <Route
        path="/questionnaire-assistant/review/feed/:oid"
        element={<QuestionnaireReview user={user} feedView />}
      />
      <Route
        path="/meeting-assistant"
        element={<ComingSoonPage title="Meeting Assistant" />}
      />
      <Route
        path="/knowledge-items"
        element={<KnowledgeItemsTable user={user} />}
      />
      <Route
        path="/knowledge-items/:docOid"
        element={<KnowledgeItemPage user={user} />}
      />
      <Route
        path="/live-assistant"
        element={<StartWebLiveAssistantPage user={user} />}
      />
      <Route
        path="/live-assistant/:callOid"
        element={<WebLiveAssistedCallPage user={user} />}
      />
      <Route
        path="/live-assistant-simulation/:callOid"
        element={<WebLiveAssistantSimulationPage user={user} />}
      />
      <Route path="/slack-link-account" element={<SlackLinkAccount />} />
      <Route
        path="/slack/success/:teamId/:appId"
        element={<SlackInstallSuccess />}
      />
      <Route path="/slack/error" element={<SlackInstallError />} />
      <Route path="/assignments" element={<AssignmentsPage user={user} />} />
      <Route path="/reference-search" element={<ReferenceSearchPage />} />
      <Route path="/chat" element={<ChatPage user={user} />} />
      <Route path="/chat/:sessionOid" element={<ChatPage user={user} />} />
      <Route
        path="/group/settings"
        element={<GroupSettingsPage user={user} />}
      />
      <Route
        path="/group/settings/:settingsPage"
        element={<GroupSettingsPage user={user} />}
      />
      <Route path="/group/switch" element={<UserGroupsManager />} />
      <Route path="/profile" element={<UserProfilePage user={user} />} />
      <Route
        path="/profile/notification-preferences"
        element={<NotificationPreferences />}
      />
      <Route
        path="/notifications"
        element={<NotificationsPage user={user} />}
      />
      <Route path="/group/settings/billing/plans" element={<PlansPage />} />

      <Route
        path="/questionnaires-workflow"
        element={<Navigate to="/questionnaire-assistant" replace />}
      />
      <Route
        path="/questionnaires-workflow/review/:oid"
        element={<Navigate to="/questionnaire-assistant" replace />}
      />
      <Route
        path="/groups"
        element={<Navigate to="/group/settings" replace />}
      />
      <Route
        path="/group"
        element={<Navigate to="/group/settings" replace />}
      />
      <Route
        path="/group/reporting"
        element={<Navigate to="/group/settings/reporting" replace />}
      />
      <Route
        path="/billing"
        element={<Navigate to="/group/settings" replace />}
      />
      <Route
        path="/billing/plans"
        element={<Navigate to="/group/settings/billing/plans" replace />}
      />
      <Route path="/answer-search" element={<Navigate to="/chat" replace />} />
      <Route path="/live-coaching" element={LazyLiveCoachingPage} />
      <Route
        path="/live-assistant-web"
        element={<Navigate to="/live-assistant" replace />}
      />
      <Route
        path="/live-assistant-web/:callOid"
        element={<Navigate to="/live-assistant" replace />}
      />

      {/* /slack is linked to from Slack "App Homepage" button */}
      <Route path="/slack" element={<Navigate to="/" replace />} />
      <Route path="/signup" element={<Navigate to="/" replace />} />
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="/invite/:id" element={<AcceptInvitePage />} />
      <Route path="/admin/*" element={LazyAdminPage} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

const TopLevelPageRouter: React.FC = () => {
  const [collapsed, setCollapsed] = useLocalStorage<boolean>(
    "quilt__sidebarCollapsed",
    false,
  )
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { authUser, hasPerm, flags } = useActiveGroup()

  const [topNavItems, bottomNavItems, narrowNavItems] = useMemo(
    () => [
      getNavItems(authUser, navigate, flags, hasPerm("quiltAdmin")),
      getBottomNavItems(authUser, navigate, flags, collapsed, setCollapsed),
      getNarrowNavItems(authUser, navigate, flags, hasPerm("quiltAdmin")),
    ],
    [authUser, navigate, flags, hasPerm, setCollapsed, collapsed],
  )

  const cleanedPathname = pathname[0] === "/" ? pathname.slice(1) : pathname
  const selectedPageKey = (
    topNavItems.find(
      (item) =>
        item.key &&
        (item.path === cleanedPathname || cleanedPathname.startsWith(item.key)),
    ) ?? topNavItems[0]
  ).key

  return (
    <Layout className="h-screen bg-white">
      <Sider
        width="250px"
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        trigger={null}
        theme="light"
        className="!bg-gray-25 max-sm:hidden"
      >
        <div className="flex h-full flex-col">
          <Link to="/">
            <QuiltLogo
              className="mx-[24px] my-5"
              width={collapsed ? "26px" : "80px"}
              small={collapsed}
            />
          </Link>
          <Menu
            id="sidebar-main-menu"
            theme="light"
            mode="inline"
            selectedKeys={[selectedPageKey]}
            items={topNavItems}
            className="bg-gray-25 !border-none"
          />
          <Menu
            id="sidebar-bottom-menu"
            className="bg-gray-25 mt-auto"
            theme="light"
            mode="inline"
            selectedKeys={EMPTY_ARRAY}
            items={bottomNavItems}
          />
        </div>
      </Sider>
      <Layout className="flex h-screen overflow-y-hidden bg-white">
        <div className="flex items-center justify-between p-2 pb-0 sm:hidden">
          <QuiltLogo className="" width="80px" />
          <Dropdown menu={{ items: narrowNavItems }} trigger={["click"]}>
            <Button>
              <MenuIcon />
            </Button>
          </Dropdown>
        </div>
        <Content className="flex h-full flex-col overflow-auto">
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
            <TourProvider>
              <TypesenseSearchProvider>
                <AppRoutes user={authUser} flags={flags} />
              </TypesenseSearchProvider>
            </TourProvider>
          </ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  )
}

const TopLevelPageContainer: React.FC = () => (
  <ActiveUserAuthorizationProvider>
    <ActiveGroupProvider>
      <TopLevelPageRouter />
      <SubscriptionExpiredBanner />
    </ActiveGroupProvider>
  </ActiveUserAuthorizationProvider>
)

export default TopLevelPageContainer

import Button from "antd/es/button"
import Space from "antd/es/space"
import {
  Gamepad2Icon,
  MicIcon,
  MicOffIcon,
  PlayIcon,
  SquareIcon,
  Trash2Icon,
} from "lucide-react"

import type { Settings } from "../../live/LiveSettingsButton"
import LiveSettingsButton from "../../live/LiveSettingsButton"
import type { PlayState } from "./useTranscriptProcessor"

interface Props {
  playState: PlayState
  onStart: () => void
  onStop: () => void
  onPause: () => void
  settings: Settings
  onChangeSettings: (settings: Partial<Settings>) => void
  isSimulation?: boolean
  onResetSimulation?: () => void
}

const WebCallControls: React.FC<Props> = ({
  playState,
  onStart,
  onStop,
  onPause,
  settings,
  onChangeSettings,
  isSimulation,
  onResetSimulation,
}) => {
  const playIcon = isSimulation ? <Gamepad2Icon /> : <PlayIcon />
  const playingText = isSimulation ? "Simulate" : "Start"
  const stopStartButton =
    playState === "playing" ||
    playState === "paused" ||
    playState === "stopping" ? (
      <Button
        type="primary"
        className="w-32"
        disabled={playState === "stopping"}
        onClick={onStop}
      >
        <SquareIcon />
        {playState === "stopping" ? "Stopping..." : "Stop"}
      </Button>
    ) : (
      <Button
        type="primary"
        className="w-32"
        disabled={playState === "starting"}
        onClick={onStart}
      >
        {playIcon}
        {playState === "starting" ? "Starting..." : playingText}
      </Button>
    )
  const muteUnmuteButton = (
    <Button
      type="primary"
      onClick={playState === "playing" ? onPause : onStart}
      disabled={
        isSimulation || !(playState === "paused" || playState === "playing")
      }
    >
      {playState === "paused" ? <MicOffIcon /> : <MicIcon />}
      {playState === "paused" ? "Unmute" : "Mute"}
    </Button>
  )

  const resetButton = (
    <Button
      type="primary"
      className="w-32"
      danger
      onClick={onResetSimulation}
      disabled={playState === "starting" || playState === "playing"}
    >
      <Trash2Icon size={20} />
      Reset Data
    </Button>
  )

  return (
    <Space className="flex-wrap items-center">
      {stopStartButton}
      {!isSimulation && muteUnmuteButton}
      <LiveSettingsButton
        disabled={playState !== "stopped"}
        settings={settings}
        onChange={onChangeSettings}
        showSimulationSettings={isSimulation}
      />
      {isSimulation && resetButton}
    </Space>
  )
}

export default WebCallControls

import Button from "antd/es/button"
import Tooltip from "antd/es/tooltip"
import { ThumbsUpIcon } from "lucide-react"
import { CheckIcon, MinusIcon } from "lucide-react"
import { useCallback, useMemo, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import type { SheetDiscussion } from "../../discussions/types"
import useAnswerFeedback from "../../hooks/useAnswerFeedback"
import { isVerbatimFromAnswerBank } from "../../hooks/useEnrichedReference"
import useErrorPopup from "../../hooks/useErrorPopup"
import { getColumnNumber } from "../../sheets/utils"
import type {
  AnswerReference,
  StoredGeneratedAnsweredQuestion,
} from "../../types/answerer"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import type { WorksheetRange } from "../../types/sheets"
import { toHumanReadableString } from "../../utils"
import AssignQuestionnaireAnswerButton from "../AssignQuestionnaireAnswerForm"
import BadAnswerButton from "../BadAnswerButton"
import Discussions from "../Discussions/Discussions"
import { ReferenceLink } from "../ReferenceLink"
import { useGridContext } from "./GridContext"
import { reviewAnswer } from "./utils"

const SURFACE = "WEB_QUESTIONNAIRE_ASSISTANT"

interface Props {
  references?: AnswerReference[]
  activeGroupOid: string
  selectedSheet: string
  job: AnswerQuestionnaireJob
  discussions?: SheetDiscussion[]
  answer?: StoredGeneratedAnsweredQuestion
  discussionsError?: Error
  location: WorksheetRange
}

const CellSidebarPane: React.FC<Props> = ({
  references,
  activeGroupOid,
  selectedSheet,
  job,
  discussions,
  answer,
  discussionsError,
  location,
}) => {
  const { cellFocused, onClickLocation } = useGridContext()
  const { authUser } = useActiveUserAuthorizationFromContext()
  const { handleError, handleSuccess } = useErrorPopup()
  const { onGoodAnswer } = useAnswerFeedback(
    SURFACE,
    answer?.last_quilt_answer_request_id,
  )

  const [changingReviewState, setChangingReviewState] = useState(false)

  const filteredDiscussions = useMemo(() => {
    if (!discussions?.length || !cellFocused) return
    return discussions.filter((discussion) => {
      const range = discussion.location
      return (
        range.sheetName === selectedSheet &&
        getColumnNumber(cellFocused.colId) == range.firstColIndex &&
        cellFocused.rowIndex + 1 == range.firstRowIndex
      )
    })
  }, [cellFocused, discussions, selectedSheet])

  const onChangeReview = useCallback(
    async (kind: "REVIEW" | "UNREVIEW") => {
      if (!answer?.oid) return
      setChangingReviewState(true)
      try {
        await reviewAnswer(kind, job.oid, [answer])
        handleSuccess(
          kind === "REVIEW" ? "Answer approved!" : "Answer unapproved!",
        )
      } catch (error) {
        handleError({ error, prefix: "Couldn't change review status" })
      } finally {
        setChangingReviewState(false)
      }
    },
    [handleSuccess, handleError, answer, job.oid],
  )

  const reviewItem = useCallback(async () => {
    return await onChangeReview("REVIEW")
  }, [onChangeReview])

  const unreviewItem = useCallback(async () => {
    return await onChangeReview("UNREVIEW")
  }, [onChangeReview])

  const canApproveOrTransferOwnership =
    job.creator.uid === authUser.uid ||
    answer?.last_assigned_to?.uid === authUser.uid

  const isReviewed = !!answer?.last_reviewed_by

  return (
    <>
      {references?.length ? (
        <div>
          <h3>
            <span className="mr-1 font-bold text-purple-500">
              {`${cellFocused?.colId}${(cellFocused?.rowIndex ?? 0) + 1}`}
            </span>
            References
          </h3>
          {references.map((r, idx) => (
            <ReferenceLink
              index={idx}
              key={`${r.document_oid}:${r.piece_oid}:${r?.knowledge_item_oid}`}
              reference={r}
              loadEagerly
              isVerbatimFromAnswerBank={isVerbatimFromAnswerBank(references)}
            />
          ))}
        </div>
      ) : answer ? (
        <div className="mt-4">No references to display</div>
      ) : null}

      {answer ? (
        <div>
          <div className="mt-2 flex gap-2">
            <Tooltip placement="topRight" title="Good Answer">
              <Button
                icon={<ThumbsUpIcon />}
                size="small"
                disabled={!answer}
                onClick={onGoodAnswer}
              />
            </Tooltip>
            <BadAnswerButton
              surface={SURFACE}
              answererRequestId={answer.last_quilt_answer_request_id}
              unableToAnswer={answer.confidence === 0}
              disabled={!answer}
            />
          </div>
          {canApproveOrTransferOwnership ||
          answer.last_reviewed_by ||
          answer.last_assigned_to ? (
            <h3 className="mb-0 mt-4">Approval</h3>
          ) : null}
          {canApproveOrTransferOwnership ? (
            <div className="mt-2 flex">
              <AssignQuestionnaireAnswerButton
                jobOid={job.oid}
                answerOids={[answer.oid]}
                assignedTo={answer.last_assigned_to}
              />
              {isReviewed ? (
                <Button
                  className="submit ml-2 flex items-center"
                  onClick={unreviewItem}
                  icon={<MinusIcon />}
                  title="Remove approval"
                  disabled={changingReviewState}
                >
                  Unapprove
                </Button>
              ) : (
                <Button
                  className="submit ml-2 flex items-center"
                  onClick={reviewItem}
                  type="primary"
                  icon={<CheckIcon />}
                  title="Mark as Approved"
                  disabled={changingReviewState}
                >
                  {changingReviewState ? "Approving..." : "Approve"}
                </Button>
              )}
            </div>
          ) : null}
          {answer.last_reviewed_by ? (
            <div className="mt-2 flex items-center">
              <span className="text-sm text-gray-500">
                Last approved by {answer.last_reviewed_by.email} on{" "}
                {toHumanReadableString(answer.last_reviewed_by.created_at)}
              </span>
            </div>
          ) : null}
          {answer.last_assigned_to ? (
            <div className="mt-2 flex items-center">
              <span className="text-sm text-gray-500">
                Owner changed to {answer.last_assigned_to.email} on{" "}
                {toHumanReadableString(answer.last_assigned_to.created_at)}
              </span>
            </div>
          ) : null}
        </div>
      ) : null}

      <Discussions
        group_oid={activeGroupOid}
        discussions={filteredDiscussions}
        job_id={job.oid}
        location={location}
        kind="SHEET"
        discussionsError={discussionsError}
        onClickLocation={onClickLocation}
      />
    </>
  )
}

export default CellSidebarPane

import Card from "antd/es/card"
import Skeleton from "antd/es/skeleton"
import { collection, limit, orderBy, query, where } from "firebase/firestore"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import type { GeneratedAnsweredQuestion } from "../../types/answerer"
import { GROUPS_COLLECTION } from "../../types/common"
import {
  ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
  ANSWER_QUESTIONNAIRE_JOBS_HISTORY_SUBCOLLECTION,
} from "../../types/jobs"
import type {
  AnswerQuestionnaireHistoryItem,
  UserAnswer,
} from "../../types/jobs"
import { toHumanReadableString } from "../../utils"

interface CellHistoryProps {
  answer_oid: string
  job_oid: string
  group_oid: string
}

const getAnswerBlock = (answer: UserAnswer | GeneratedAnsweredQuestion) => {
  return (
    <>
      <div>{answer.primary_answer.text}</div>
      {answer.secondary_answer ? (
        <>
          <hr className="my-2 border border-slate-100" />
          <div>{answer.secondary_answer.text}</div>
        </>
      ) : null}
    </>
  )
}

const HistoryItem: React.FC<{ item: AnswerQuestionnaireHistoryItem }> = ({
  item,
}) => {
  let title: string
  let content: React.ReactNode

  if (item.kind === "EDIT_RESPONSE") {
    title = `Answer Edited by ${item.actor.email}`
    content = getAnswerBlock(item.answer)
  } else if (item.kind === "QUILT_RESPONSE") {
    title = "Answer Generated by Quilt"
    content = getAnswerBlock(item.answer)
  } else if (item.kind === "ASSIGN") {
    title = `Owner changed by ${item.actor.email}`
    content = `Owner changed to ${item.assign_to.email || "Unknown User"}`
  } else if (item.kind === "UNASSIGN") {
    title = `Owner removed by ${item.actor.email}`
    content = "Removed owner"
  } else if (item.kind === "REVIEW") {
    title = `Approved by ${item.actor.email}`
    content = "Marked as approved"
  } else if (item.kind === "UNREVIEW") {
    title = `Removed approval by ${item.actor.email}`
    content = "Removed approval"
  } else if (item.kind === "EDIT_CELL") {
    title = `Cell edited by ${item.actor.email}`
    content = item.text_content
  } else {
    title = "Unknown Action"
    content = "An unknown action occurred"
  }

  return (
    <Card
      size="small"
      className="my-4"
      title={
        <div className="py-2">
          <div>{toHumanReadableString(item.created_at)}</div>
          <div className="font-normal">{title}</div>
        </div>
      }
    >
      {content}
    </Card>
  )
}

const CellHistory: React.FC<CellHistoryProps> = ({
  answer_oid,
  job_oid,
  group_oid,
}) => {
  const colRef = collection(
    db,
    GROUPS_COLLECTION,
    group_oid,
    ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
    job_oid,
    ANSWER_QUESTIONNAIRE_JOBS_HISTORY_SUBCOLLECTION,
  ).withConverter(makeConverter<AnswerQuestionnaireHistoryItem>())

  const historyQuery = query(
    colRef,
    where("question_oid", "==", answer_oid),
    orderBy("created_at", "desc"),
    limit(100),
  )

  const [historyItems, historyLoading, historyError] =
    useCollectionData(historyQuery)

  if (historyLoading) {
    return <Skeleton className="mt-4" active paragraph={{ rows: 4 }} />
  }

  if (historyError) {
    return (
      <div className="mt-4 rounded-md bg-red-100 p-4">
        Error loading history: {historyError.message}
      </div>
    )
  }

  if (historyItems?.length === 0) {
    return (
      <div className="mt-4 rounded-md bg-red-100 p-4">No history found</div>
    )
  }

  return (
    <div className="pb-2">
      {historyItems?.map((item) => <HistoryItem key={item.oid} item={item} />)}
    </div>
  )
}

export default CellHistory

import Button from "antd/es/button"
import List from "antd/es/list"
import type { User as AuthUser } from "firebase/auth"
import { collection, limit, orderBy, query, where } from "firebase/firestore"
import { useCallback } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Link } from "react-router-dom"
import { useMap } from "usehooks-ts"

import ExternalLink from "../components/ExternalLink"
import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import useErrorPopup from "../hooks/useErrorPopup"
import { getInterpretableTimeString } from "../timeUtils"
import { deleteLiveAssistedCall, sendChatToLiveAssistedCall } from "./api"
import { LIVE_ASSISTED_CALLS_COLLECTION } from "./db"
import type { LiveAssistedCall } from "./types"

interface Props {
  user: AuthUser
}

const LiveAssistedCallItem: React.FC<{
  call: LiveAssistedCall
  sendTestChat: (call: LiveAssistedCall, message: string) => void
  removeCall: (call: LiveAssistedCall) => void
  sendingChat: boolean | undefined
  removing: boolean | undefined
}> = ({ call, sendTestChat, sendingChat, removing, removeCall }) => {
  let meetingSpecificContent: React.ReactNode
  if (call.meeting_type === "ZOOM") {
    meetingSpecificContent = (
      <>
        <div className="grow">
          <div>
            Join URL: <ExternalLink href={call.join_url} />
          </div>
          <div>{getInterpretableTimeString(call.created_at)}</div>
        </div>
        <div>Bot state: {call.bot_state}</div>
        <div className="ml-2">
          <Button
            type="default"
            danger
            disabled={sendingChat || removing}
            onClick={() => sendTestChat(call, "Test chat message")}
          >
            Send Test Chat
          </Button>
        </div>
      </>
    )
  } else if (call.meeting_type === "SIMULATION") {
    meetingSpecificContent = (
      <div className="grow">
        <Link to={`/live-assistant-simulation/${call.oid}`}>
          Go to simulation
        </Link>
        <div>{getInterpretableTimeString(call.created_at)}</div>
      </div>
    )
  } else {
    meetingSpecificContent = (
      <div className="grow">
        <Link to={`/live-assistant/${call.oid}`}>Go to call</Link>
        <div>{getInterpretableTimeString(call.created_at)}</div>
      </div>
    )
  }

  return (
    <div className="flex p-2">
      {meetingSpecificContent}
      <div className="ml-2">
        <Button
          type="default"
          danger
          disabled={removing}
          onClick={() => removeCall(call)}
        >
          Delete
        </Button>
      </div>
    </div>
  )
}

const LiveAssistedCallsList: React.FC<Props> = ({ user }) => {
  const { handleError } = useErrorPopup()
  const [calls, loading, error] = useCollectionData(
    query(
      collection(db, LIVE_ASSISTED_CALLS_COLLECTION).withConverter(
        makeConverter<LiveAssistedCall>(),
      ),
      where("creator_uid", "==", user.uid),
      orderBy("created_at", "desc"),
      limit(200),
    ),
  )
  const [removingMap, setRemoveMap] = useMap<string, boolean>()
  const [sendingTestMap, setSendingTestMap] = useMap<string, boolean>()

  const removeCall = useCallback(
    async (call: LiveAssistedCall) => {
      setRemoveMap.set(call.oid, true)
      try {
        await deleteLiveAssistedCall(call)
      } catch (error) {
        handleError({ prefix: "Error removing call", error })
      } finally {
        setRemoveMap.remove(call.oid)
      }
    },
    [handleError, setRemoveMap],
  )

  const onSendTestChatMessage = async (
    call: LiveAssistedCall,
    message: string,
  ) => {
    setSendingTestMap.set(call.oid, true)
    try {
      await sendChatToLiveAssistedCall(call, message)
    } catch (error) {
      handleError({ prefix: "Error sending chat", error })
    } finally {
      setSendingTestMap.remove(call.oid)
    }
  }

  if (error) {
    console.error("Error loading calls", error)
    return <div>Error loading calls</div>
  }

  return (
    <List
      header="Your Previous Calls"
      bordered
      dataSource={calls}
      renderItem={(call) => (
        <LiveAssistedCallItem
          key={call.oid}
          call={call}
          sendTestChat={onSendTestChatMessage}
          removeCall={removeCall}
          sendingChat={sendingTestMap.get(call.oid)}
          removing={removingMap.get(call.oid)}
        />
      )}
      loading={loading}
    />
  )
}

export default LiveAssistedCallsList

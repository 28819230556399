import Button from "antd/es/button"
import Form from "antd/es/form"
import Input from "antd/es/input"
import Popover from "antd/es/popover"
import { PlusIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { createGroupInvite } from "../../groups/api"
import type { Group } from "../../groups/types"
import { useKeydown } from "../../hooks/events"
import useErrorPopup from "../../hooks/useErrorPopup"

interface AddInviteFormProps {
  onClose: () => void
  group: Group
}

const AddInviteForm: React.FC<AddInviteFormProps> = ({ onClose, group }) => {
  const [email, setEmail] = useState<string>("")
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  useKeydown(27 /*escape*/, onClose)

  const { handleSuccess, handleError } = useErrorPopup()

  const onEmailChange = (e: React.FormEvent<HTMLInputElement>) =>
    setEmail(e.currentTarget.value)

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const onSubmit = async () => {
    if (!email) {
      return
    }
    setSubmitting(true)
    try {
      await createGroupInvite({
        invitee_email: email,
        group_oid: group.oid,
      })
      setEmail("")
      onClose()
      handleSuccess("Invitation sent successfully!")
    } catch (error) {
      handleError({ error, prefix: "Couldn't send invitation" })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Form
      className="invite-member-form"
      layout="inline" // For horizontal layout
      onFinish={onSubmit}
    >
      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            message: (
              <span className="text-xs text-slate-400">
                {`Seems like there's a typo`}
              </span>
            ),
          },
        ]}
      >
        <Input
          style={{ width: "auto" }}
          onChange={onEmailChange}
          value={email}
          autoFocus
          placeholder="member@example.com"
        />
      </Form.Item>

      <Form.Item shouldUpdate={true}>
        <Button
          type="primary"
          htmlType="submit"
          icon={<PlusIcon />}
          loading={isSubmitting}
          disabled={isSubmitting || !isValidEmail(email)}
        ></Button>
      </Form.Item>
    </Form>
  )
}

interface AddMemberButtonProps {
  group: Group
}
const AddMemberButton: React.FC<AddMemberButtonProps> = ({ group }) => {
  const [open, setOpen] = useState(false)
  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Popover
      content={<AddInviteForm onClose={hide} group={group} />}
      trigger="click"
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
    >
      <Button
        type="primary"
        id="groups-invite-member-button"
        title="Invite Member"
        className="invite-member-button flex items-center justify-center"
      >
        Invite Member
      </Button>
    </Popover>
  )
}

export default AddMemberButton

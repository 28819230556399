import type { WorksheetRange } from "../../types/sheets"
import { getColumnLetter } from "../../utils"

const FeedTitleCTA: React.FC<{
  location: WorksheetRange
  className?: string
}> = ({ location: discLocation, className = "" }) => {
  const locationLabel = `${getColumnLetter(discLocation.firstColIndex)}${discLocation.firstRowIndex}`
  return (
    <div className={"flex items-center font-bold " + className}>
      <div className="text-primary m-0 p-0 font-bold">{locationLabel}</div>
      <span className="ml-1">DISCUSSION</span>
    </div>
  )
}

export default FeedTitleCTA

import Button from "antd/es/button"
import Checkbox from "antd/es/checkbox"
import Mentions from "antd/es/mentions"
import Popover from "antd/es/popover"
import { MessageSquareIcon } from "lucide-react"
import { useCallback, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { createComments } from "../discussions/api"
import type { GroupMembership } from "../groups/types"
import useErrorPopup from "../hooks/useErrorPopup"
import { useGroupMembers } from "../hooks/useGroupMembers"
import type { WorksheetRange } from "../types/sheets"

interface BulkCommentFormProps {
  jobOid: string
  answerLocations: (WorksheetRange | null | undefined)[]
  onClose: () => void
}

const BulkCommentForm: React.FC<BulkCommentFormProps> = ({
  jobOid,
  answerLocations,
  onClose,
}) => {
  const [comment, setComment] = useState<string>("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [assignedMember, setAssignedMember] = useState<GroupMembership>()
  const { handleError, handleSuccess } = useErrorPopup()
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const [members, loading] = useGroupMembers(activeGroupOid)

  const handleSubmit = async () => {
    if (!comment.trim()) {
      handleError({ error: "Please enter a comment" })
      return
    }

    setIsSubmitting(true)
    try {
      const createCommentArgs = {
        requests: answerLocations
          .filter((location): location is WorksheetRange => location != null)
          .map((location) => ({
            group_oid: activeGroupOid,
            text: comment,
            assignment: assignedMember
              ? {
                  uid: assignedMember.uid,
                  email: assignedMember.email,
                }
              : undefined,
            kind: "SHEET" as const,
            location,
            job_id: jobOid,
          })),
      }

      await createComments(createCommentArgs)
      handleSuccess("Comments added successfully")
      setComment("")
      setAssignedMember(undefined)
      onClose()
    } catch (error) {
      handleError({ error, prefix: "Failed to add comments" })
    } finally {
      setIsSubmitting(false)
    }
  }

  const mentionedMember = members?.find((member) =>
    Mentions.getMentions(comment)
      .map((m) => m.value)
      .includes(member.email),
  )

  return (
    <div className="w-[400px]">
      <Mentions
        autoSize
        allowClear
        placeholder="Enter your comment here..."
        options={members?.map((member) => ({
          value: member.email,
          label: member.email,
        }))}
        value={comment}
        onChange={(text) => setComment(text)}
        rows={4}
        loading={loading}
      />
      <div className="mt-4 flex items-center justify-between">
        {mentionedMember && (
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setAssignedMember(mentionedMember)
              } else {
                setAssignedMember(undefined)
              }
            }}
            className="text-xs"
          >
            Assign to {mentionedMember.email}
          </Checkbox>
        )}
        <div>
          <Button onClick={onClose} className="mr-2">
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={!comment.trim() || isSubmitting}
          >
            Add Comment
          </Button>
        </div>
      </div>
    </div>
  )
}

interface BulkCommentButtonProps {
  jobOid: string
  answerLocations: (WorksheetRange | null | undefined)[]
  small?: boolean
}

export const BulkCommentButton: React.FC<BulkCommentButtonProps> = ({
  jobOid,
  answerLocations,
  small,
}) => {
  const [open, setOpen] = useState(false)
  const hide = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Popover
      content={
        <BulkCommentForm
          jobOid={jobOid}
          answerLocations={answerLocations}
          onClose={hide}
        />
      }
      trigger="click"
      placement="bottomLeft"
      open={open}
      onOpenChange={setOpen}
    >
      <Button
        icon={<MessageSquareIcon />}
        title="Add Comment"
        className="mr-2 flex items-center justify-center"
        size={small ? "small" : "middle"}
        disabled={answerLocations.length === 0}
      >
        Comment
      </Button>
    </Popover>
  )
}

import { httpsCallable } from "../serverUtils"
import type {
  GetSlackUsersRequest,
  GetSlackUsersResponse,
  SlackChannel,
  SlackConfiguration,
  SlackInstallationMeta,
  SlackTeamUserAssociationRequest,
} from "./apiTypes"

export const associateSlackIdWithUserUid = httpsCallable<
  SlackTeamUserAssociationRequest,
  void
>("associateSlackIdWithUserUid")

export const getSlackInstallationApi = httpsCallable<
  { groupOid: string },
  SlackInstallationMeta | undefined
>("getSlackInstallation")

export const updateSlackConfiguration = httpsCallable<{
  newConfig: SlackConfiguration
}>("updateSlackConfiguration")

export const getSlackChannelsApi = httpsCallable<void, SlackChannel[]>(
  "getSlackChannels",
)

export const getSlackWorkspaceMembersApi = httpsCallable<
  GetSlackUsersRequest,
  GetSlackUsersResponse
>("getSlackWorkspaceMembers")

export const disconnectSlackBot = httpsCallable<void, void>(
  "disconnectSlackBot",
)

import Button from "antd/es/button"
import Popover from "antd/es/popover"
import Upload from "antd/es/upload"
import { CloudUploadIcon } from "lucide-react"
import { useState } from "react"

import createJob from "../../components/QuestionnaireWorkflowWizard/createJob"
import { QuestionnaireWorkflowWizardFromFiles } from "../../components/QuestionnaireWorkflowWizard/index"
import type { DocumentFile } from "../../components/QuestionnaireWorkflowWizard/state"
import { fileReaderAsync } from "../../components/QuestionnaireWorkflowWizard/utils"
import useErrorPopup from "../../hooks/useErrorPopup"
import { MimeType } from "../../types/mimetype"

const { Dragger } = Upload

const QUESTIONNAIRE_ASSISTANT_MIMETYPES = [
  MimeType.XLSX,
  MimeType.XLSM,
  MimeType.CSV,
]

const AddQuestionnaireWorkflowButton: React.FC = () => {
  const [files, setFiles] = useState<DocumentFile[]>([])
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const { handleSuccess, handleError } = useErrorPopup()

  const handleFileChange = async (file: File): Promise<boolean> => {
    if (file === undefined) {
      handleError({ error: null, message: "No file selected" })
      return false
    }
    const fileType = file.type as MimeType
    if (!QUESTIONNAIRE_ASSISTANT_MIMETYPES.includes(fileType)) {
      handleError({
        error: null,
        message: "Invalid file type. Please upload an Excel file",
      })
      return false
    }

    try {
      const arrayBuffer = await fileReaderAsync(file)
      if (!(arrayBuffer instanceof ArrayBuffer)) {
        throw new Error("Unable to parse file")
      }
      setFiles((prevFiles) => [
        ...prevFiles,
        {
          fileName: file.name,
          fileType,
          fileContents: arrayBuffer,
        },
      ])
      setOpen(false)
      setModalOpen(true)
      handleSuccess("File parsed successfully")
    } catch (error) {
      handleError({ error, prefix: "Couldn't parse file" })
    }

    return false
  }

  return (
    <>
      {modalOpen && (
        <QuestionnaireWorkflowWizardFromFiles
          onClose={() => {
            setModalOpen(false)
            setFiles([])
          }}
          files={files}
          handleDocumentProcessing={createJob}
          isCompletedQuestionnaire={false}
        />
      )}
      <Popover
        placement="bottomLeft"
        open={open}
        onOpenChange={(newOpen) => setOpen(newOpen)}
        trigger="click"
        content={
          <div className="m-4">
            <Dragger
              className="h-full"
              beforeUpload={handleFileChange}
              showUploadList={false}
            >
              <div className="p-2">
                <CloudUploadIcon
                  className="text-primary mx-auto my-4"
                  size="48"
                />
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Excel XLSX/XLSM and CSV files are supported.
                </p>
              </div>
            </Dragger>
          </div>
        }
      >
        <Button
          type="primary"
          onClick={() => setOpen(!open)}
          icon={<CloudUploadIcon />}
          className="h-9 font-bold"
        >
          Upload Questionnaire
        </Button>
      </Popover>
    </>
  )
}

export default AddQuestionnaireWorkflowButton

export const UserFacingErrorType = {
  UNKNOWN: "UNKNOWN",
  NOT_FOUND: "NOT_FOUND",
  INVALID_DOCUMENT: "INVALID_DOCUMENT",
  USER_HAS_NO_DOCUMENTS: "USER_HAS_NO_DOCUMENTS",
  INVALID_STATE_FOR_ACTION: "INVALID_STATE_FOR_ACTION",
  NOT_YET_IMPLEMENTED: "NOT_YET_IMPLEMENTED",
  BAD_ANSWER_LOCATION: "BAD_ANSWER_LOCATION",
  MISSING_FIELD: "MISSING_FIELD",
  UNSPECIFIED: "UNSPECIFIED",
  NO_QUESTIONS_FOUND: "NO_QUESTIONS_FOUND",
  UNKNOWN_SHEET_LAYOUT: "UNKNOWN_SHEET_LAYOUT",
  UNAUTHORIZED: "UNAUTHORIZED",
  USER_DISABLED: "USER_DISABLED",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  NO_TEXT_FOUND: "NO_TEXT_FOUND",
  NO_SLACKBOT_CONFIGURATION_FOUND: "NO_SLACKBOT_CONFIGURATION_FOUND",
}

export type UserFacingErrorType = keyof typeof UserFacingErrorType

import { collection, limit, orderBy, query, where } from "firebase/firestore"
import { useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import {
  DISCUSSION_COLLECTION,
  type SheetDiscussion,
} from "../../discussions/types"
import { db } from "../../firebaseApp"
import type { StoredGeneratedAnsweredQuestion } from "../../types/answerer"
import { GROUPS_COLLECTION } from "../../types/common"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import FeedLoadingState from "./FeedLoading"
import QuestionnaireReviewFeed from "./QuestionnaireReviewFeed"
import type { AnswerWithDiscussion } from "./types"
import { locationToKey } from "./utils"

interface QuestionnaireReviewFeedWrapperProps {
  url: string
  job: AnswerQuestionnaireJob
  answers: StoredGeneratedAnsweredQuestion[]
}

const QuestionnaireReviewFeedWrapper: React.FC<
  QuestionnaireReviewFeedWrapperProps
> = ({ job, answers }) => {
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()

  const discussionsQuery = query(
    collection(db, GROUPS_COLLECTION, activeGroupOid, DISCUSSION_COLLECTION),
    where("job_id", "==", job.oid),
    where("deleted", "==", false),
    orderBy("created_at", "asc"),
    limit(1000),
  ).withConverter(makeConverter<SheetDiscussion>())

  const [discussions, discussionsLoading, discussionsError] =
    useCollectionData<SheetDiscussion>(discussionsQuery)

  const answersWithDiscussions = useMemo((): AnswerWithDiscussion[] => {
    if (!discussions) return answers

    const discussionMap = new Map<string, SheetDiscussion[]>()
    discussions.forEach((discussion) => {
      const key = locationToKey(discussion.location)
      if (!discussionMap.has(key)) {
        discussionMap.set(key, [])
      }
      discussionMap.get(key)!.push(discussion)
    })

    return answers
      .sort(
        (b, a) =>
          (b.primary_question.location?.firstRowIndex || 0) -
          (a.primary_question.location?.firstRowIndex || 0),
      )
      .map((answer) => {
        const location = answer.primary_answer.location
        if (!location) return answer

        const key = locationToKey(location)
        const relatedDiscussions = discussionMap.get(key) || []

        return {
          ...answer,
          discussions: relatedDiscussions,
        }
      })
  }, [answers, discussions])

  if (discussionsLoading) {
    return <FeedLoadingState />
  }

  return (
    <QuestionnaireReviewFeed
      answers={answersWithDiscussions}
      job={job}
      discussionsError={discussionsError}
    />
  )
}

export default QuestionnaireReviewFeedWrapper

import Button from "antd/es/button"
import Checkbox from "antd/es/checkbox"
import Empty from "antd/es/empty"
import Input from "antd/es/input"
import { CheckIcon, LinkIcon, SearchIcon, XIcon } from "lucide-react"
import VirtualList from "rc-virtual-list"
import { useCallback, useMemo, useState } from "react"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import useComponentHeight from "../../hooks/useComponentHeight"
import useErrorPopup from "../../hooks/useErrorPopup"
import { useFeedFilters } from "../../hooks/useQuestionnaireFeedFilters"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import { sleep } from "../../utils"
import AssignQuestionnaireAnswerButton from "../AssignQuestionnaireAnswerForm"
import { BulkCommentButton } from "../BulkCommentButton"
import FeedFilterPopover from "../FeedFilterPopover"
import QuestionnaireReviewFeedCard from "./QuestionnaireReviewFeedCard"
import type { AnswerWithDiscussion } from "./types"
import { reviewAnswer } from "./utils"

interface QuestionnaireReviewFeedProps {
  answers: AnswerWithDiscussion[]
  job: AnswerQuestionnaireJob
  discussionsError?: Error
}

const QuestionnaireReviewFeed: React.FC<QuestionnaireReviewFeedProps> = ({
  answers,
  job,
  discussionsError,
}) => {
  const {
    filterAnswers,
    filterState,
    resetFilters,
    setFilterValue,
    activeFilterCount,
  } = useFeedFilters()
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedAnswers, setSelectedAnswers] = useState<Set<string>>(new Set())
  const { handleError, handleSuccess } = useErrorPopup()
  const [approving, setApproving] = useState(false)
  const { authUser } = useActiveUserAuthorizationFromContext()

  const filteredAnswers = useMemo(() => {
    const searchLower = searchTerm.toLowerCase()
    return filterAnswers(answers).filter((a) => {
      return (
        a.primary_question.text.toLowerCase().includes(searchLower) ||
        a.primary_answer.text.toLowerCase().includes(searchLower)
      )
    })
  }, [answers, filterAnswers, searchTerm])

  const canChangeOwnership = Array.from(selectedAnswers).every((oid) => {
    const answer = filteredAnswers.find((a) => a.oid === oid)
    return (
      job.creator.uid === authUser.uid ||
      answer?.last_assigned_to?.uid === authUser.uid
    )
  })

  const assignedTo = useMemo(() => {
    if (selectedAnswers.size === 0) return undefined

    const selectedAnswerObjects = filteredAnswers.filter((a) =>
      selectedAnswers.has(a.oid),
    )
    const firstAssignedTo = selectedAnswerObjects[0]?.last_assigned_to

    return selectedAnswerObjects.every(
      (a) => a.last_assigned_to?.uid === firstAssignedTo?.uid,
    )
      ? firstAssignedTo
      : undefined
  }, [selectedAnswers, filteredAnswers])

  const filteredAnswerIndices = useMemo(() => {
    const result = new Map<string, number>()
    for (const [i, a] of filteredAnswers.entries()) {
      result.set(a.oid, i)
    }
    return result
  }, [filteredAnswers])

  const handleSelect = useCallback(
    (answer: AnswerWithDiscussion, event: React.MouseEvent) => {
      const index = filteredAnswerIndices.get(answer.oid)
      if (index === undefined) return

      setSelectedAnswers((prev) => {
        const newSet = new Set(prev)
        if (event.shiftKey && prev.size > 0) {
          document.getSelection?.()?.removeAllRanges()
          const lastSelectedIndex = filteredAnswers.findIndex((a) =>
            prev.has(a.oid),
          )
          if (lastSelectedIndex !== -1) {
            const start = Math.min(lastSelectedIndex, index)
            const end = Math.max(lastSelectedIndex, index)
            for (let i = start; i <= end; i++) {
              newSet.add(filteredAnswers[i].oid)
            }
          } else {
            newSet.add(answer.oid)
          }
        } else {
          if (newSet.has(answer.oid)) {
            newSet.delete(answer.oid)
          } else {
            newSet.add(answer.oid)
          }
        }
        return newSet
      })
    },
    [filteredAnswers, filteredAnswerIndices],
  )

  const handleSelectAll = useCallback(() => {
    if (selectedAnswers.size === 0) {
      setSelectedAnswers(new Set(filteredAnswers.map((a) => a.oid)))
    } else {
      setSelectedAnswers(new Set())
    }
  }, [filteredAnswers, selectedAnswers.size])

  const handleApproveAll = useCallback(async () => {
    try {
      setApproving(true)

      const selectedAnswerObjects = filteredAnswers.filter((a) =>
        selectedAnswers.has(a.oid),
      )
      await reviewAnswer("REVIEW", job.oid, selectedAnswerObjects)
      handleSuccess("Selected answers approved successfully!")
      setSelectedAnswers(new Set())
    } catch (error) {
      handleError({ error, prefix: "Couldn't approve selected answers" })
    } finally {
      setApproving(false)
    }
  }, [selectedAnswers, filteredAnswers, job.oid, handleSuccess, handleError])

  const handleUnapproveAll = useCallback(async () => {
    setApproving(true)
    try {
      const selectedAnswerObjects = filteredAnswers.filter((a) =>
        selectedAnswers.has(a.oid),
      )
      await reviewAnswer("UNREVIEW", job.oid, selectedAnswerObjects)
      handleSuccess("Selected answers unapproved successfully!")
      setSelectedAnswers(new Set())
    } catch (error) {
      handleError({ error, prefix: "Couldn't unapprove selected answers" })
    }
    setApproving(false)
  }, [selectedAnswers, filteredAnswers, job.oid, handleSuccess, handleError])

  const filterBySelection = useCallback(async () => {
    const answerOids = Array.from(selectedAnswers)
    setFilterValue("oid", answerOids)
    // Wait for the filter to be applied.
    await sleep(1)
    await navigator.clipboard.writeText(window.location.href)
    handleSuccess("Copied shareable link to clipboard!")
  }, [selectedAnswers, handleSuccess, setFilterValue])

  const approvedAnswers = filteredAnswers.filter(
    (a) => !!a.last_reviewed_by,
  ).length

  const answerLocations = useMemo(
    () =>
      Array.from(selectedAnswers).map(
        (oid) =>
          filteredAnswers.find((a) => a.oid === oid)?.primary_answer.location,
      ),
    [selectedAnswers, filteredAnswers],
  )

  const [listRef, listHeight] = useComponentHeight()

  const changing = approving

  return (
    <div className="flex w-full flex-col">
      <div className="border-gray-25 w-full border-b bg-white pb-4">
        <div className="my-4 mr-6 flex items-center">
          <Input
            placeholder="Search questions and answers"
            prefix={<SearchIcon />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mr-2 w-full"
          />
          <FeedFilterPopover
            answers={answers}
            setFilterValue={setFilterValue}
            filterState={filterState}
            resetFilters={resetFilters}
            activeFilterCount={activeFilterCount}
          />
        </div>
        <div className="flex items-center">
          <Checkbox
            checked={
              filteredAnswers.length > 0 &&
              selectedAnswers.size === filteredAnswers.length
            }
            indeterminate={
              selectedAnswers.size > 0 &&
              selectedAnswers.size < filteredAnswers.length
            }
            onChange={handleSelectAll}
          >
            Select All
          </Checkbox>
          <span className="ml-4 mr-4 min-w-[6rem] max-w-[6rem] text-gray-600">
            {selectedAnswers.size} selected
          </span>

          <Button
            size="small"
            type="primary"
            icon={<CheckIcon />}
            onClick={handleApproveAll}
            disabled={changing || selectedAnswers.size === 0}
            className="mr-2"
          >
            Approve
          </Button>
          <Button
            size="small"
            icon={<XIcon />}
            onClick={handleUnapproveAll}
            disabled={changing || selectedAnswers.size === 0}
            className="mr-2"
          >
            Unapprove
          </Button>
          <BulkCommentButton
            small
            jobOid={job.oid}
            answerLocations={answerLocations}
          />
          {canChangeOwnership && (
            <AssignQuestionnaireAnswerButton
              jobOid={job.oid}
              answerOids={Array.from(selectedAnswers)}
              assignedTo={assignedTo}
              small
            />
          )}
          <Button
            size="small"
            icon={<LinkIcon />}
            onClick={filterBySelection}
            disabled={changing || selectedAnswers.size === 0}
            className="ml-2"
          >
            Selected
          </Button>

          <div className="ml-auto mr-4 flex items-center">
            <span className="mr-2 font-semibold text-gray-700">
              {approvedAnswers} / {filteredAnswers.length} approved
            </span>
          </div>
        </div>
      </div>
      <div ref={listRef} className="grow overflow-y-hidden px-2">
        {filteredAnswers.length > 0 ? (
          <VirtualList
            data={filteredAnswers}
            itemKey="oid"
            height={listHeight}
            itemHeight={180}
          >
            {(answer: AnswerWithDiscussion) => (
              <div>
                <QuestionnaireReviewFeedCard
                  answer={answer}
                  job={job}
                  discussionsError={discussionsError}
                  focused={filterState.oid.includes(answer.oid)}
                  isSelected={selectedAnswers.has(answer.oid)}
                  onSelect={handleSelect}
                  loading={approving && selectedAnswers.has(answer.oid)}
                />
              </div>
            )}
          </VirtualList>
        ) : (
          <Empty
            className="m-auto flex h-96 flex-col items-center justify-center rounded-md border"
            description={
              activeFilterCount > 0
                ? "No answers match the selected filters"
                : "No answers available"
            }
          />
        )}
      </div>
    </div>
  )
}

export default QuestionnaireReviewFeed

import type { CellValueChangedEvent, GridApi } from "@ag-grid-community/core"
import "@ag-grid-community/styles/ag-grid.css"
import "@ag-grid-community/styles/ag-theme-balham.css"
import { collection, limit, orderBy, query, where } from "firebase/firestore"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { read as xlsxRead } from "xlsx"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import {
  DISCUSSION_COLLECTION,
  type SheetDiscussion,
} from "../../discussions/types"
import { getErrorMessage } from "../../errors"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import { createQuestionnaireJobEdit } from "../../pages/QuestionnaireAssistant/api"
import { getWorksheetJsonData } from "../../sheets/sheetsJsHelpers"
import { getColumnNumber } from "../../sheets/utils"
import type {
  SheetText,
  StoredGeneratedAnsweredQuestion,
} from "../../types/answerer"
import { GROUPS_COLLECTION } from "../../types/common"
import type {
  AnswerQuestionnaireCellEdit,
  AnswerQuestionnaireJob,
} from "../../types/jobs"
import type { WorksheetRange } from "../../types/sheets"
import { getColumnLetter, sortByCreatedAtAsc } from "../../utils"
import { GridProvider } from "./GridContext"
import GridLoadingState from "./GridLoading"
import QuestionnaireReviewSheet from "./QuestionnaireReviewSheet"
import QuestionnaireSidebar from "./QuestionnaireSidebar"
import { focusCell } from "./focusCell"
import type { CellFocused, GridContext, RawRowData, RowData } from "./types"
import { updateAnswerByLocation } from "./utils"

interface QuestionnaireReviewSheetWrapperProps {
  url: string
  job: AnswerQuestionnaireJob
  answers: StoredGeneratedAnsweredQuestion[]
  edits: AnswerQuestionnaireCellEdit[]
  sidebarOpen: boolean
  setSidebarOpen: (open: boolean) => void
}

interface SheetData {
  name: string
  data: RawRowData[]
}

const getGridDataForSheet = (
  selectedSheetData: SheetData,
  sheetName: string,
  answers: StoredGeneratedAnsweredQuestion[],
  edits: AnswerQuestionnaireCellEdit[],
  discussions: SheetDiscussion[] | undefined,
): RowData[] => {
  const gridData = selectedSheetData.data.map((row, i) => {
    // Cast required because index type for RowData is ill defined.
    const newRow = {
      rowId: `${sheetName}:${i}`,
    } as RowData
    Object.keys(row).forEach((colId) => {
      newRow[colId] = {
        rawContent: row[colId],
      }
    })
    return newRow
  })

  const maybeInsertAnswer = (
    subAnswer: SheetText | null,
    answer: StoredGeneratedAnsweredQuestion,
  ) => {
    if (!subAnswer || !subAnswer.location) return
    if (subAnswer.location.sheetName !== sheetName) return
    const rowIdx = subAnswer.location.firstRowIndex - 1
    if (rowIdx >= gridData.length) return
    const colLetter = getColumnLetter(subAnswer.location.firstColIndex)

    gridData[rowIdx][colLetter] = {
      rawContent: subAnswer.text ?? "",
      answer,
    }
  }

  // Apply answers.
  for (const ans of answers) {
    maybeInsertAnswer(ans.primary_answer, ans)
    maybeInsertAnswer(ans.secondary_answer, ans)
  }

  // Apply edits.
  for (const edit of edits.toSorted(sortByCreatedAtAsc)) {
    if (edit.location.sheetName !== sheetName) {
      continue
    }
    const editRow = edit.location.firstRowIndex - 1
    if (editRow >= gridData.length) {
      // Discard edits past the end.
      continue
    }

    const colKey = getColumnLetter(edit.location.firstColIndex)
    const row = gridData[editRow]
    const existingCell = row[colKey]
    if (
      existingCell?.edit &&
      existingCell.edit.created_at._compareTo(edit.created_at) > 0
    ) {
      // Skip this edit if there is already a newer one.
      continue
    }

    row[colKey] = {
      ...existingCell,
      rawContent: edit.text_content,
      edit,
    }
  }

  // Insert discussions into gridData.
  const sheetDiscussions =
    discussions?.filter((d) => d.location.sheetName === sheetName) ?? []
  for (const discussion of sheetDiscussions) {
    const range = discussion.location
    const rowIdx = range.firstRowIndex - 1
    if (rowIdx >= gridData.length) {
      // Discard discussions past the end.
      continue
    }

    const colLetter = getColumnLetter(range.firstColIndex)
    const cell = gridData[rowIdx][colLetter]
    if (!cell) {
      continue
    }
    gridData[rowIdx][colLetter] = {
      ...cell,
      hasDiscussions: true,
    }
  }
  return gridData
}

async function createEdit(
  job_oid: string,
  sheetName: string,
  rowIndex: number,
  colIndex: number,
  text: string,
): Promise<void> {
  await createQuestionnaireJobEdit({
    job_oid,
    location: {
      sheetName,
      sheetId: undefined,
      firstRowIndex: rowIndex,
      lastRowIndex: rowIndex,
      firstColIndex: colIndex,
      lastColIndex: colIndex,
    },
    text_content: text,
  })
}

function cellToKey(
  sheetName: string,
  rowIndex: number,
  colIndex: number,
): string {
  return `${sheetName}:${rowIndex}:${colIndex}`
}

const DEFAULT_CELL_FOCUSED: CellFocused = {
  rowIndex: 0,
  colId: "A",
}

const QuestionnaireReviewSheetWrapper: React.FC<
  QuestionnaireReviewSheetWrapperProps
> = ({ url, job, answers, edits, sidebarOpen, setSidebarOpen }) => {
  const [error, setError] = useState<React.ReactNode>()
  const [loading, setLoading] = useState(true)
  const [sheetsData, setSheetsData] = useState<SheetData[]>([])
  const { handleError } = useErrorPopup()
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()

  const sheetNames = useMemo(
    () => sheetsData.map((sheet) => sheet.name),
    [sheetsData],
  )

  const [cellFocused, setCellFocused] =
    useState<CellFocused>(DEFAULT_CELL_FOCUSED)
  const [gridApi, setGridApi] = useState<GridApi>()
  const [selectedSheet, setSelectedSheet] = useState<string>("")

  const colRef = collection(
    db,
    GROUPS_COLLECTION,
    activeGroupOid,
    DISCUSSION_COLLECTION,
  ).withConverter(makeConverter<SheetDiscussion>())

  const discussionsQuery = query(
    colRef,
    where("job_id", "==", job.oid),
    where("deleted", "==", false),
    orderBy("created_at", "asc"),
    limit(100),
  )

  const [discussions, discussionsLoading, discussionsError] =
    useCollectionData<SheetDiscussion>(discussionsQuery)

  // Function that returns the answer for a given cell, if any.
  const findAnswer = useMemo(() => {
    const cellRangeToAnswer = new Map<string, StoredGeneratedAnsweredQuestion>()
    for (const ans of answers) {
      if (ans.primary_answer && ans.primary_answer.location) {
        cellRangeToAnswer.set(
          cellToKey(
            ans.primary_answer.location.sheetName ?? "",
            ans.primary_answer.location.firstRowIndex,
            ans.primary_answer.location.firstColIndex,
          ),
          ans,
        )
      }
      if (ans.secondary_answer && ans.secondary_answer.location) {
        cellRangeToAnswer.set(
          cellToKey(
            ans.secondary_answer.location.sheetName ?? "",
            ans.secondary_answer.location.firstRowIndex,
            ans.secondary_answer.location.firstColIndex,
          ),
          ans,
        )
      }
    }
    return (
      sheetName: string,
      rowIndex: number,
      colIndex: number,
    ): StoredGeneratedAnsweredQuestion | undefined => {
      return cellRangeToAnswer.get(cellToKey(sheetName, rowIndex + 1, colIndex))
    }
  }, [answers])

  const grid = useMemo(() => {
    const selectedSheetData = sheetsData.find(
      (sheet) => sheet.name === selectedSheet,
    )
    if (!selectedSheetData) return
    return getGridDataForSheet(
      selectedSheetData,
      selectedSheet,
      answers,
      edits,
      discussions,
    )
  }, [sheetsData, selectedSheet, answers, edits, discussions])

  const onCellValueEdited = useCallback(
    async ({
      rowIndex,
      column,
      newValue,
      context,
    }: CellValueChangedEvent<RowData>) => {
      if (rowIndex === null || column === null) {
        return
      }
      const colId = column.getColId()
      const colIndex = getColumnNumber(colId)
      const text = String(newValue)
      const gridContext = context as GridContext

      // Note that the input rowIndex and the one expected by findAnswer are
      // 0-indexed whereas the indices in the API are 1-indexed.
      const answer = findAnswer(gridContext.sheetName, rowIndex, colIndex)

      try {
        if (answer) {
          await updateAnswerByLocation(
            answer,
            job.oid,
            gridContext.sheetName,
            rowIndex + 1,
            colIndex,
            text,
          )
        } else {
          await createEdit(
            job.oid,
            gridContext.sheetName,
            rowIndex + 1,
            colIndex,
            text,
          )
        }
      } catch (error) {
        handleError({ error })
      }
    },
    [findAnswer, job.oid, handleError],
  )

  useEffect(() => {
    const fetchAndProcessSheets = async () => {
      try {
        const response = await fetch(url)
        const arrayBuffer = await response.arrayBuffer()
        const workbook = xlsxRead(arrayBuffer)
        const allSheetsData: SheetData[] = workbook.SheetNames.filter(
          (s) =>
            !workbook.Workbook?.Sheets?.find((ws) => ws.name === s)?.Hidden,
        ).map((sheetName) => ({
          name: sheetName,
          data: getWorksheetJsonData<RawRowData>(workbook, sheetName, {
            // We drop empty columns after applying edits and answers.
            dropInnerEmptyColumns: false,
          }),
        }))

        // TODO(mgraczyk): Handle case with no sheets more explicitly
        const firstSheet =
          allSheetsData.length > 0
            ? allSheetsData[0].name
            : workbook.SheetNames[0]

        setSheetsData(allSheetsData)
        setSelectedSheet(firstSheet)
      } catch (error) {
        setError(
          getErrorMessage({ error, prefix: "Couldn't load the document" }),
        )
      } finally {
        setLoading(false)
      }
    }
    void fetchAndProcessSheets()
  }, [url])

  const onRegenerate = useCallback((rowIndex: number) => {
    // TODO(iashris): Implement this when we have the API.
    console.log("Regenerate", rowIndex)
  }, [])

  const onSaveToKnowledgeBase = useCallback((rowIndex: number) => {
    // TODO: Implement this when we have the API.
    console.log("Save to knowledge base", rowIndex)
  }, [])

  const onMarkAsResolved = useCallback((rowIndex: number) => {
    // TODO: Implement this when we have the API.
    console.log("Mark as resolved", rowIndex)
  }, [])

  const openSidebar = useCallback(() => {
    setSidebarOpen(true)
  }, [setSidebarOpen])

  const changeToSheet = useCallback(
    (sheetName: string) => {
      // Cancel before switching so that we don't save on the switched-to sheet.
      gridApi?.stopEditing(/*cancel=*/ false)
      setSelectedSheet(sheetName)
    },
    [gridApi],
  )

  const onClickLocation = useCallback(
    (location: WorksheetRange) => {
      const { firstRowIndex, firstColIndex } = location
      focusCell(firstRowIndex - 1, firstColIndex, gridApi)
    },
    [gridApi],
  )

  if (loading || discussionsLoading) {
    return <GridLoadingState />
  }

  if (error) {
    return <div className="text-center text-red-600">{error}</div>
  }

  if (!sheetsData.length || !grid) {
    return <div className="text-center">No data to display.</div>
  }

  const focusedAnswer = findAnswer(
    selectedSheet,
    cellFocused.rowIndex,
    getColumnNumber(cellFocused.colId),
  )

  return (
    <GridProvider
      value={{
        setCellFocused,
        cellFocused,
        onClickLocation,
        gridApi,
        setGridApi,
      }}
    >
      <QuestionnaireReviewSheet
        jobOid={job.oid}
        grid={grid}
        onCellValueEdited={onCellValueEdited}
        onSaveToKnowledgeBase={onSaveToKnowledgeBase}
        selectedSheet={selectedSheet}
        changeToSheet={changeToSheet}
        sheetNames={sheetNames}
        sidebarOpen={sidebarOpen}
        openSidebar={openSidebar}
      />
      <QuestionnaireSidebar
        open={sidebarOpen}
        setOpen={setSidebarOpen}
        activeGroupOid={activeGroupOid}
        selectedSheet={selectedSheet}
        onRegenerate={onRegenerate}
        onMarkAsResolved={onMarkAsResolved}
        job={job}
        discussions={discussions}
        answer={focusedAnswer}
        discussionsError={discussionsError}
      />
    </GridProvider>
  )
}

export default QuestionnaireReviewSheetWrapper

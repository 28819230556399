import type { UserDocumentSourceKind } from "../types/userDocument"

export const SOURCE_KIND_DESCRIPTION: Record<UserDocumentSourceKind, string> = {
  FILEUPLOAD: "Uploaded to Quilt",
  GOOGLE_DRIVE: "Imported from Google Drive",
  PUBLIC_WEB_PAGE: "Public Web Page",
  NOTION: "Imported from Notion",
  CONFLUENCE: "Imported from Confluence",
  QUESTIONNAIRE_ASSISTANT_IMPORT: "Imported from Questionnaire Assistant",
  COMPLETED_QUESTIONNAIRE: "Imported from Completed Questionnaire",
  MANUAL: "Manually Created",
  UNKNOWN: "Unknown",
  CARBON_ONEDRIVE: "Imported from OneDrive",
  CARBON_SHAREPOINT: "Imported from SharePoint",
  MERGE_ONEDRIVE: "Imported from OneDrive",
  CARBON_CONFLUENCE: "Imported from Confluence",
  CARBON_NOTION: "Imported from Notion",
  CARBON_SLACK: "Imported from Slack",
  GONG: "Imported from Gong",
  SLACK: "Imported from Slack",
}

import { sendAnalyticsEvent } from "../../analytics"
import {
  reviewQuestionnaireJobAnswers,
  unreviewQuestionnaireJobAnswers,
  updateQuestionnaireJobUserAnswer,
} from "../../pages/QuestionnaireAssistant/api"
import type { StoredGeneratedAnsweredQuestion } from "../../types/answerer"
import type { SheetsRange, WorksheetRange } from "../../types/sheets"
import { getColumnLetter } from "../../utils"

export const getCellLabel = (location?: SheetsRange | null): string | null => {
  if (!location) return null
  const column = getColumnLetter(location.firstColIndex)
  return `${column}${location.firstRowIndex}`
}

export async function updateAnswerByLocation(
  answer: StoredGeneratedAnsweredQuestion,
  job_oid: string,
  sheetName: string,
  rowIndex: number,
  colIndex: number,
  text: string,
): Promise<void> {
  const update = {
    primary_answer: answer.primary_answer,
    secondary_answer: answer.secondary_answer,
    ...(answer.primary_answer.location &&
    answer.primary_answer.location.sheetName === sheetName &&
    answer.primary_answer.location.firstRowIndex === rowIndex &&
    answer.primary_answer.location.firstColIndex === colIndex
      ? { primary_answer: { text } }
      : {}),
    ...(answer.secondary_answer &&
    answer.secondary_answer.location &&
    answer.secondary_answer.location.sheetName === sheetName &&
    answer.secondary_answer.location.firstRowIndex === rowIndex &&
    answer.secondary_answer.location.firstColIndex === colIndex
      ? { secondary_answer: { text } }
      : {}),
  }

  await updateQuestionnaireJobUserAnswer(job_oid, answer.oid, update)
}

interface UpdateAnswersParams {
  answer: StoredGeneratedAnsweredQuestion
  job_oid: string
  primaryText: string
  secondaryText?: string
}

export async function updateAnswer({
  answer,
  job_oid,
  primaryText,
  secondaryText,
}: UpdateAnswersParams): Promise<void> {
  const update = {
    primary_answer: { ...answer.primary_answer, text: primaryText },
    secondary_answer: answer.secondary_answer
      ? {
          ...answer.secondary_answer,
          text: secondaryText || answer.secondary_answer.text,
        }
      : null,
  }

  await updateQuestionnaireJobUserAnswer(job_oid, answer.oid, update)
}

const SURFACE = "WEB_QUESTIONNAIRE_ASSISTANT"

export async function reviewAnswer(
  kind: "REVIEW" | "UNREVIEW",
  job_oid: string,
  answers: StoredGeneratedAnsweredQuestion[],
): Promise<void> {
  if (kind === "REVIEW") {
    await reviewQuestionnaireJobAnswers(
      job_oid,
      answers.map((answer) => ({
        job_oid: job_oid,
        answer_oid: answer.oid,
        expected_contents: {
          primary_answer: answer.primary_answer,
          secondary_answer: answer.secondary_answer,
        },
      })),
    )
    for (const answer of answers) {
      void sendAnalyticsEvent({
        event_type: "APPROVE_QUESTIONNAIRE_ANSWER",
        surface: SURFACE,
        event_data: {
          job_oid,
          answer_oid: answer.oid,
        },
      })
    }
  } else {
    await unreviewQuestionnaireJobAnswers(
      job_oid,
      answers.map((answer) => ({
        job_oid: job_oid,
        answer_oid: answer.oid,
      })),
    )
    for (const answer of answers) {
      void sendAnalyticsEvent({
        event_type: "UNAPPROVE_QUESTIONNAIRE_ANSWER",
        surface: SURFACE,
        event_data: {
          job_oid,
          answer_oid: answer.oid,
        },
      })
    }
  }
}

export const locationToKey = (location: WorksheetRange): string => {
  return `${location.sheetName}-${location.firstRowIndex}-${location.firstColIndex}`
}

import { type QuerySnapshot, collection } from "firebase/firestore"
import { createContext, useContext } from "react"
import {
  useCollectionData,
  useCollectionDataOnce,
} from "react-firebase-hooks/firestore"

import { makeConverter } from "../dbUtils"
import { db } from "../firebaseApp"
import type { GroupMembership } from "../groups/types"
import {
  GROUPS_COLLECTION,
  GROUP_MEMBERSHIP_SUBCOLLECTION,
} from "../types/common"

type GroupMembersContextType = [
  GroupMembership[] | undefined,
  boolean,
  Error | undefined,
  QuerySnapshot<GroupMembership> | undefined,
]

const GroupMembersContext = createContext<GroupMembersContextType | undefined>(
  undefined,
)

export const GroupMembersProvider: React.FC<{
  group_oid: string
  children: React.ReactNode
}> = ({ group_oid, children }) => {
  const value = useCollectionData(
    collection(
      db,
      GROUPS_COLLECTION,
      group_oid,
      GROUP_MEMBERSHIP_SUBCOLLECTION,
    ).withConverter(makeConverter<GroupMembership>()),
  )
  return (
    <GroupMembersContext.Provider value={value}>
      {children}
    </GroupMembersContext.Provider>
  )
}

export const useGroupMembers = (group_oid: string): GroupMembersContextType => {
  const resultFromContext = useContext(GroupMembersContext)
  if (resultFromContext) {
    console.log("resultFromContext", resultFromContext)
    return resultFromContext
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCollectionData(
    collection(
      db,
      GROUPS_COLLECTION,
      group_oid,
      GROUP_MEMBERSHIP_SUBCOLLECTION,
    ).withConverter(makeConverter<GroupMembership>()),
  )
}

export const useGroupMembersOnce = (
  group_oid: string,
): [
  GroupMembership[] | undefined,
  boolean,
  Error | undefined,
  QuerySnapshot<GroupMembership> | undefined,
  () => Promise<void>,
] => {
  return useCollectionDataOnce(
    collection(
      db,
      GROUPS_COLLECTION,
      group_oid,
      GROUP_MEMBERSHIP_SUBCOLLECTION,
    ).withConverter(makeConverter<GroupMembership>()),
  )
}

import Button from "antd/es/button"
import Form from "antd/es/form"
import Select from "antd/es/select"
import Switch from "antd/es/switch"
import { useCallback, useState } from "react"

import useErrorPopup from "../hooks/useErrorPopup"
import { updateSlackConfiguration } from "../slack/api"
import type { SlackChannel, SlackInstallationMeta } from "../slack/apiTypes"

interface FormDataType {
  enabledOnAllChannels: boolean
  selectedChannels: string[]
}

interface SlackConfigurationFormProps {
  slackInstallation?: SlackInstallationMeta
  accessibleSlackChannels: SlackChannel[]
  refetchSlackInstallation: () => Promise<void>
  onClose: () => void
  loading: boolean
}

const getInitialValues = (
  slackInstallation?: SlackInstallationMeta,
): FormDataType => {
  if (!slackInstallation) {
    return {
      enabledOnAllChannels: true,
      selectedChannels: [],
    }
  }
  return {
    enabledOnAllChannels: slackInstallation.configuration.enabledOnAllChannels,
    selectedChannels: Object.keys(
      slackInstallation.configuration.channels,
    ).filter((id) => slackInstallation.configuration.channels[id].enabled),
  }
}

const formDataToSlackConfig = (
  formData: FormDataType,
): SlackInstallationMeta["configuration"] => {
  const { enabledOnAllChannels, selectedChannels } = formData
  if (enabledOnAllChannels) {
    return {
      enabledOnAllChannels,
      channels: {},
    }
  }
  return {
    enabledOnAllChannels,
    channels: selectedChannels.reduce<{ [id: string]: { enabled: boolean } }>(
      (accum, id) => {
        accum[id] = { enabled: true }
        return accum
      },
      {},
    ),
  }
}

const SlackConfigurationForm: React.FC<SlackConfigurationFormProps> = ({
  slackInstallation,
  accessibleSlackChannels,
  refetchSlackInstallation,
  onClose,
  loading,
}) => {
  const [form] = Form.useForm<FormDataType>()
  const { handleError, handleSuccess } = useErrorPopup()
  const [formSubmitting, setFormSubmitting] = useState(false)

  const onFormSubmit = useCallback(
    async (formValues: FormDataType) => {
      setFormSubmitting(true)
      try {
        await updateSlackConfiguration({
          newConfig: formDataToSlackConfig(formValues),
        })
        await refetchSlackInstallation()
        handleSuccess("Updated Slack Configuration")
        onClose()
      } catch (e) {
        handleError({
          prefix: "Unable to update Slack Configuration",
          error: e,
        })
      } finally {
        setFormSubmitting(false)
      }
    },
    [handleError, handleSuccess, refetchSlackInstallation, onClose],
  )

  const enabledOnAllChannels = Form.useWatch("enabledOnAllChannels", form)
  const channelOptions = accessibleSlackChannels.map((channel) => ({
    label: channel.name,
    value: channel.id,
  }))
  return (
    <Form
      className="min-w-[500px] max-w-[700px]"
      form={form}
      initialValues={getInitialValues(slackInstallation)}
      disabled={formSubmitting || loading}
      onFinish={onFormSubmit}
      layout="vertical"
    >
      <Form.Item
        layout="horizontal"
        label={
          <span
            className={`pl-3 ${enabledOnAllChannels ? "font-bold" : "font-normal"} text-black`}
          >
            Enable access to all channels
          </span>
        }
        name="enabledOnAllChannels"
        valuePropName="checked"
        className={`mb-0 rounded-lg py-2 ${enabledOnAllChannels ? "bg-purple-25" : "bg-gray-25"}`}
        colon={false}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label={
          <span className="mt-3 text-black">Specify Accessible Channels</span>
        }
        name="selectedChannels"
        colon={false}
      >
        <Select<string[]>
          mode="multiple"
          placeholder="Select channels"
          options={channelOptions}
          optionFilterProp="label"
          loading={formSubmitting || loading}
          disabled={enabledOnAllChannels}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={formSubmitting}>
          Save Configuration
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SlackConfigurationForm

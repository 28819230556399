import Skeleton from "antd/es/skeleton"

import { getErrorMessage } from "../../errors"
import { useEnrichedReference } from "../../hooks/useEnrichedReference"
import ReferenceBadge from "../../hooks/useEnrichedReference/ReferenceBadge"
import type { AnswerReference } from "../../types/answerer"

const ChatReference: React.FC<{
  reference: AnswerReference
  idx: number
  loading: boolean
  loadKnowledgeItemEagerly?: boolean
  isVerbatimFromAnswerBank?: boolean
  className?: string
}> = ({
  reference,
  isVerbatimFromAnswerBank,
  idx,
  loadKnowledgeItemEagerly,
  className = "",
}) => {
  const { enrichedReference, isLoading, error } = useEnrichedReference(
    reference,
    "v2",
    loadKnowledgeItemEagerly,
  )

  const content = isLoading ? (
    <Skeleton active paragraph={{ rows: 4 }} />
  ) : error ? (
    getErrorMessage({
      prefix: "Could not load the reference. It may have been deleted",
      error,
    })
  ) : (
    enrichedReference.content
  )

  const title = isLoading
    ? "Loading..."
    : error
      ? "Error Loading"
      : enrichedReference.title
  const subtitle = isLoading ? null : enrichedReference.subtitle

  const contentUrl = isLoading || error ? "" : enrichedReference.contentUrl

  return (
    <div className={"overflow-x-hidden " + className}>
      <div className="flex items-center gap-2">
        <a
          className="text-primary font-medium"
          href={contentUrl}
          target="_blank"
        >
          <div className="flex items-center gap-2">
            <div className="h-4 w-4 shrink-0 rounded-full bg-gray-100 text-center text-xs font-bold text-gray-800">
              {idx + 1}
            </div>
            <div>{title}</div>
          </div>
        </a>
        <a href={enrichedReference.path} target="_blank">
          <ReferenceBadge
            reference={reference}
            isVerbatimFromAnswerBank={isVerbatimFromAnswerBank}
          />
        </a>
      </div>
      {subtitle && (
        <div className="text-sm font-normal text-slate-500">{subtitle}</div>
      )}
      <div className="mt-4">{content}</div>
    </div>
  )
}

export default ChatReference

import Skeleton from "antd/es/skeleton"
import Table from "antd/es/table"
import { collection, doc } from "firebase/firestore"
import {
  useCollectionData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore"
import { Link, useParams } from "react-router-dom"

import { useActiveUserAuthorizationFromContext } from "../../contexts/ActiveUserAuthorizationContext"
import { makeConverter } from "../../dbUtils"
import { db } from "../../firebaseApp"
import useErrorPopup from "../../hooks/useErrorPopup"
import type {
  SheetText,
  StoredGeneratedAnsweredQuestion,
} from "../../types/answerer"
import { GROUPS_COLLECTION } from "../../types/common"
import {
  ANSWER_QUESTIONNAIRE_JOBS_ANSWERS_SUBCOLLECTION,
  ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
  type AnswerQuestionnaireJob,
  AnswerQuestionnaireJobState,
} from "../../types/jobs"
import Header from "../Header"
import LoadingSpinner from "../LoadingSpinner"
import NotFoundPage from "../NotFoundPage"

const QuestionnaireAnswers: React.FC = () => {
  const { questionnaireOid = "" } = useParams<{
    questionnaireOid: string
  }>()
  const { activeGroupOid } = useActiveUserAuthorizationFromContext()
  const { handleError } = useErrorPopup()

  const jobRef = doc(
    db,
    GROUPS_COLLECTION,
    activeGroupOid,
    ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
    questionnaireOid,
  ).withConverter(makeConverter<AnswerQuestionnaireJob>())

  const [jobData, jobLoading, jobError] = useDocumentDataOnce(jobRef)

  const jobAnswersSubColRef = collection(
    db,
    GROUPS_COLLECTION,
    activeGroupOid,
    ANSWER_QUESTIONNAIRE_JOBS_COLLECTION,
    questionnaireOid,
    ANSWER_QUESTIONNAIRE_JOBS_ANSWERS_SUBCOLLECTION,
  ).withConverter(makeConverter<StoredGeneratedAnsweredQuestion>())

  const [answerDocs, answersLoading, answersError] =
    useCollectionData(jobAnswersSubColRef)

  let bodyContent: React.ReactNode
  if (jobLoading || answersLoading) {
    bodyContent = <LoadingSpinner />
  } else if (jobError ?? answersError) {
    handleError({ error: jobError ?? answersError })
    bodyContent = <div>Error loading answers from questionnaire</div>
  } else if (
    !jobData ||
    jobData.state === AnswerQuestionnaireJobState.REMOVING
  ) {
    bodyContent = <NotFoundPage noHeader />
  } else if (
    !(
      jobData.state === AnswerQuestionnaireJobState.REVIEWING ||
      jobData.state === AnswerQuestionnaireJobState.DONE
    )
  ) {
    bodyContent = (
      <div>Sorry, this questionnaire cannot be viewed at this time</div>
    )
  } else {
    bodyContent = (
      <Table
        dataSource={answerDocs}
        columns={[
          {
            title: "Question",
            dataIndex: "primary_question",
            key: "primary_question",
            render: (
              sheetText: SheetText,
              answer: StoredGeneratedAnsweredQuestion,
            ) => {
              return (
                <Link
                  to={`/questionnaire-assistant/list/${questionnaireOid}/${answer.oid}`}
                >
                  {sheetText.text}
                </Link>
              )
            },
          },
          {
            title: "Primary Answer",
            dataIndex: "primary_answer",
            key: "primary_answer",
            render: (sheetText: SheetText) => <span>{sheetText.text}</span>,
          },
          {
            title: "Secondary Question",
            dataIndex: "secondary_question",
            key: "secondary_question",
            render: (sheetText: SheetText) =>
              sheetText ? <span>{sheetText.text}</span> : "N/A",
          },
          {
            title: "Secondary Answer",
            dataIndex: "secondary_answer",
            key: "secondary_answer",
            render: (sheetText: SheetText) =>
              sheetText ? <span>{sheetText.text}</span> : "N/A",
          },
        ]}
      />
    )
  }
  return (
    <>
      <Header
        title="Questionnaire Responses"
        breadcrumbs={[
          {
            title: "Questionnaire Assistant",
            href: "/questionnaire-assistant",
          },
          {
            title: `${jobData?.title ?? questionnaireOid} Responses`,
            href: `/questionnaire-assistant/review/${questionnaireOid}/responses`,
          },
        ]}
      />
      <Skeleton loading={jobLoading || answersLoading}>
        <div className="m-4 flex flex-col gap-2">{bodyContent}</div>
      </Skeleton>
    </>
  )
}

export default QuestionnaireAnswers

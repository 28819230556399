import Button from "antd/es/button"
import Input from "antd/es/input"
import { CheckIcon, PencilIcon } from "lucide-react"
import { useState } from "react"

import type { SheetsRange } from "../../types/sheets"
import type { AnswerWithDiscussion } from "./types"
import { getCellLabel } from "./utils"

const { TextArea } = Input

const CellLabel: React.FC<{
  location: SheetsRange
  jobOid: string
}> = ({ location, jobOid }) => {
  if (!location) return null

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={`/questionnaire-assistant/review/${jobOid}?sheetName=${location?.sheetName}&firstRowIndex=${location?.firstRowIndex}&firstColIndex=${location?.firstColIndex}`}
      className="hover:bg-primary ml-2 cursor-pointer rounded-md bg-gray-50 px-1 text-sm font-bold text-gray-500 transition-all duration-200 hover:text-white"
    >
      {getCellLabel(location)}
    </a>
  )
}

interface QuestionAnswerBlockProps {
  jobOid: string
  answerObj: AnswerWithDiscussion
  onUpdateAnswer: (
    primaryAnswer: string,
    secondaryAnswer: string,
  ) => Promise<void>
}

const QuestionAnswerBlock: React.FC<QuestionAnswerBlockProps> = ({
  jobOid,
  answerObj,
  onUpdateAnswer,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [editedPrimaryAnswer, setEditedPrimaryAnswer] = useState(
    answerObj.primary_answer?.text || "",
  )
  const [editedSecondaryAnswer, setEditedSecondaryAnswer] = useState(
    answerObj.secondary_answer?.text || "",
  )

  const handleUpdateClick = async () => {
    setIsUpdating(true)
    try {
      await onUpdateAnswer(editedPrimaryAnswer, editedSecondaryAnswer)
    } finally {
      setIsUpdating(false)
      setIsEditing(false)
    }
  }

  return (
    <>
      <div className="mb-4">
        <div className="flex items-center">
          <span className="text-[14px] font-semibold text-gray-700">
            Question
          </span>
          {answerObj.primary_question.location && (
            <CellLabel
              location={answerObj.primary_question.location}
              jobOid={jobOid}
            />
          )}
        </div>
        <p className="mt-1 text-[14px] text-gray-700">
          {answerObj.primary_question.text}
        </p>
      </div>

      {answerObj.secondary_question && (
        <div className="mb-4">
          <div className="flex items-center">
            <span className="text-[14px] font-semibold text-gray-700">
              Additional Question
            </span>
            {answerObj.secondary_question.location && (
              <CellLabel
                location={answerObj.secondary_question.location}
                jobOid={jobOid}
              />
            )}
          </div>
          <p className="mt-1 text-[14px] text-gray-700">
            {answerObj.secondary_question.text}
          </p>
        </div>
      )}

      <div className="mb-4">
        <div className="flex items-center">
          <span className="text-[14px] font-semibold text-gray-700">
            Answer
          </span>
          {answerObj.primary_answer.location && (
            <CellLabel
              location={answerObj.primary_answer.location}
              jobOid={jobOid}
            />
          )}
        </div>
        {isEditing ? (
          <TextArea
            value={editedPrimaryAnswer}
            onChange={(e) => setEditedPrimaryAnswer(e.target.value)}
            autoSize={{ minRows: 2, maxRows: 6 }}
            className="mt-1"
          />
        ) : (
          <p className="mt-1 text-[14px] text-gray-700">
            {answerObj.primary_answer.text}
          </p>
        )}
      </div>

      {answerObj.secondary_answer && (
        <div className="mb-4">
          <div className="flex items-center">
            <span className="text-[14px] font-semibold text-gray-700">
              Additional Answer
            </span>
            {answerObj.secondary_answer.location && (
              <CellLabel
                location={answerObj.secondary_answer.location}
                jobOid={jobOid}
              />
            )}
          </div>
          {isEditing ? (
            <TextArea
              value={editedSecondaryAnswer}
              onChange={(e) => setEditedSecondaryAnswer(e.target.value)}
              autoSize={{ minRows: 2, maxRows: 6 }}
              className="mt-1"
            />
          ) : (
            <p className="mt-1 text-[14px] text-gray-700">
              {answerObj.secondary_answer.text}
            </p>
          )}
        </div>
      )}

      <div className="mt-4">
        {isEditing ? (
          <div className="flex">
            <Button
              icon={<CheckIcon />}
              onClick={handleUpdateClick}
              type="primary"
              size="small"
              disabled={isUpdating}
              loading={isUpdating}
            >
              Update
            </Button>
            <Button
              onClick={() => setIsEditing(false)}
              type="text"
              size="small"
              className="ml-2"
            >
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            icon={<PencilIcon />}
            onClick={() => setIsEditing(true)}
            size="small"
            className="text-xs"
          >
            Edit
          </Button>
        )}
      </div>
    </>
  )
}

export default QuestionAnswerBlock

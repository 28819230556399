import type { TabsProps } from "antd"
import Button from "antd/es/button"
import Tabs from "antd/es/tabs"
import { XIcon } from "lucide-react"

import { EMPTY_ARRAY } from "../../constants"
import type { SheetDiscussion } from "../../discussions/types"
import { getColumnNumber } from "../../sheets/utils"
import type { StoredGeneratedAnsweredQuestion } from "../../types/answerer"
import type { AnswerQuestionnaireJob } from "../../types/jobs"
import Discussions from "../Discussions/Discussions"
import CellHistory from "./CellHistory"
import CellSidebarPane from "./CellSidebarPane"
import { useGridContext } from "./GridContext"

interface QuestionnaireSidebarProps {
  open: boolean
  setOpen: (open: boolean) => void
  activeGroupOid: string
  selectedSheet: string
  job: AnswerQuestionnaireJob
  discussions?: SheetDiscussion[]
  onRegenerate: (rowIndex: number) => void
  onMarkAsResolved: (rowIndex: number) => void
  answer?: StoredGeneratedAnsweredQuestion
  discussionsError?: Error
}

const QuestionnaireSidebar: React.FC<QuestionnaireSidebarProps> = ({
  open,
  setOpen,
  activeGroupOid,
  selectedSheet,
  job,
  discussions,
  answer,
  discussionsError,
}) => {
  const { cellFocused, onClickLocation } = useGridContext()
  const references = answer?.references

  // TODO(mgraczyk): Maybe memoize this.
  const location = cellFocused
    ? {
        firstColIndex: getColumnNumber(cellFocused.colId),
        lastColIndex: getColumnNumber(cellFocused.colId),
        firstRowIndex: cellFocused.rowIndex + 1,
        lastRowIndex: cellFocused.rowIndex + 1,
        sheetName: selectedSheet,
        sheetId: undefined,
      }
    : undefined

  if (!open) return null

  const items: TabsProps["items"] = location
    ? [
        {
          key: "cell_options",
          label: "Cell",
          children: (
            <CellSidebarPane
              references={references}
              activeGroupOid={activeGroupOid}
              selectedSheet={selectedSheet}
              job={job}
              discussions={discussions}
              answer={answer}
              discussionsError={discussionsError}
              location={location}
            />
          ),
        },
        answer && {
          key: "history",
          label: "History",
          children: (
            <CellHistory
              answer_oid={answer.oid}
              group_oid={activeGroupOid}
              job_oid={job.oid}
            />
          ),
        },
        {
          key: "all_discussions",
          label: "Discussions",
          children: (
            <div className="my-4">
              <Discussions
                group_oid={activeGroupOid}
                discussions={discussions}
                job_id={job.oid}
                location={location}
                kind="SHEET"
                discussionsError={discussionsError}
                onClickLocation={onClickLocation}
                hideTitle
              />
            </div>
          ),
        },
      ].flatMap((val) => val || []) // Remove undefined items
    : EMPTY_ARRAY
  const closeButton = (
    <Button
      className="cursor-pointer"
      type="text"
      icon={<XIcon />}
      onClick={() => setOpen(false)}
    />
  )

  // NOTE: The sidebar width must match the width in QuestionnaireReviewSheet.tsx
  return (
    <Tabs
      className="w-[22rem] overflow-x-hidden px-4"
      tabBarStyle={{ margin: 0 }}
      items={items}
      tabBarExtraContent={closeButton}
    />
  )
}

export default QuestionnaireSidebar

import type { SelectProps } from "antd"
import Button from "antd/es/button"
import Popover from "antd/es/popover"
import Select from "antd/es/select"
import Switch from "antd/es/switch"
import { SettingsIcon } from "lucide-react"

import { useActiveUserAuthorizationFromContext } from "../contexts/ActiveUserAuthorizationContext"
import { isDeveloperUser } from "../utils"

const disableActorsOptions: SelectProps["options"] = [
  { label: "Transcriber", value: "TranscriberActor" },
  { label: "Segmenter", value: "SegmenterActor" },
  { label: "Live Assistant", value: "LiveAssistantMini" },
  { label: "UI Events", value: "UIEventActor" },
  { label: "Auto Search", value: "AutoSearchActor" },
]

const simulationInputEventsOptions: SelectProps["options"] = [
  { label: "AUDIO_DATA", value: "AUDIO_DATA" },
  { label: "TRANSCRIBER_OUTPUT", value: "TRANSCRIBER_OUTPUT" },
  { label: "TRANSCRIPT_SEGMENT_UPDATE", value: "TRANSCRIPT_SEGMENT_UPDATE" },
  { label: "QUESTION_FOUND", value: "QUESTION_FOUND" },
  { label: "QUESTION_ANSWERED", value: "QUESTION_ANSWERED" },
  { label: "QUESTION_NOT_ANSWERED", value: "QUESTION_NOT_ANSWERED" },
  { label: "LIVE_UI_CARD_UPDATE", value: "LIVE_UI_CARD_UPDATE" },
]

const modelNames = [
  "claude-3.5-sonnet",
  "gpt-4o",
  "gpt-4o-mini",
  "gpt-4",
  "gpt-4-turbo",
  "o1-mini",
  "o1",
  "claude-3-sonnet",
  "claude-3-haiku",
  "gemini-1.5-pro",
  "gemini-1.5-flash",
  "llama-3.1-8b",
  "llama-3.1-70b",
  "llama-3.1-405b",
  "claude-3-opus",
  "mixtral-8x7b",
]

export interface Settings {
  includeSystemAudio: boolean
  showDebug?: boolean
  disabledActors: string[]
  enableResultTts?: boolean
  simulationInputEvents?: string[]
  modelName?: string
}

const defaultDisabledActors =
  import.meta.env.VITE_LIVE_ASSISTANT_DEFAULT_DISABLED_ACTORS?.split(",")
    .map((s) => s.trim())
    .filter(Boolean) ?? []

export const DEFAULT_SETTINGS: Settings = {
  // Disable system audio by default if the browser doesn't support it.
  includeSystemAudio: !!navigator?.mediaDevices?.getDisplayMedia,
  showDebug: false,
  disabledActors: defaultDisabledActors,
  simulationInputEvents: ["TRANSCRIBER_OUTPUT"],
  modelName: "default",
}

interface Props {
  settings: Settings
  disabled?: boolean
  onChange: (settings: Partial<Settings>) => void
  showSimulationSettings?: boolean
}

const LiveSettingsButton: React.FC<Props> = ({
  settings,
  disabled = false,
  onChange,
  showSimulationSettings = false,
}) => {
  const { authUser } = useActiveUserAuthorizationFromContext()
  const simulationSettingsContent = showSimulationSettings ? (
    <>
      <div className="mb-1 mt-4 text-sm font-semibold text-gray-800">
        Simulation Input Events
      </div>
      <Select
        mode="tags"
        allowClear
        className="min-w-64"
        placeholder="Simulation Input Events"
        disabled={disabled}
        value={settings.simulationInputEvents || []}
        onChange={(kinds) => onChange({ simulationInputEvents: kinds })}
        options={simulationInputEventsOptions}
      />
    </>
  ) : null

  const settingsContent = (
    <div className="flex-none flex-col">
      <div className="mb-1 text-sm font-semibold text-gray-800">
        Audio Settings
      </div>
      <Switch
        onChange={(value) => onChange({ includeSystemAudio: value })}
        value={settings.includeSystemAudio}
        disabled={disabled}
        checkedChildren="Mic + System"
        unCheckedChildren="Mic Only"
      />
      {isDeveloperUser(authUser) && (
        <>
          <div className="mb-1 mt-4 text-sm font-semibold text-gray-800">
            Disabled Actors
          </div>
          <Select
            mode="multiple"
            allowClear
            className="min-w-64"
            placeholder="Disable actors"
            disabled={disabled}
            value={settings.disabledActors}
            onChange={(actors) => onChange({ disabledActors: actors })}
            options={disableActorsOptions}
          />
        </>
      )}
      {simulationSettingsContent}
      {isDeveloperUser(authUser) && (
        <div className="mt-4">
          <Switch
            onChange={(value) => onChange({ enableResultTts: value })}
            value={settings.enableResultTts}
            disabled={disabled}
            checkedChildren="Result TTS"
            unCheckedChildren="No Result TTS"
          />
        </div>
      )}
      {isDeveloperUser(authUser) && (
        <div className="mt-4">
          <Switch
            onChange={(value) => onChange({ showDebug: value })}
            value={settings.showDebug}
            disabled={disabled}
            checkedChildren="Show Debug"
            unCheckedChildren="Hide Debug"
          />
        </div>
      )}
      {isDeveloperUser(authUser) && settings.showDebug && (
        <>
          <div className="mb-1 mt-4 text-sm font-semibold text-gray-800">
            Model Name
          </div>
          <Select
            allowClear
            className="min-w-64"
            placeholder="model name"
            disabled={disabled}
            value={settings.modelName}
            onChange={(modelName) => onChange({ modelName: modelName })}
            options={modelNames.map((value) => ({ value, label: value }))}
          />
        </>
      )}
    </div>
  )

  return (
    <Popover content={settingsContent} trigger="click" placement="bottom">
      <Button disabled={disabled}>
        <SettingsIcon />
        <span className="max-sm:!hidden">Settings</span>
      </Button>
    </Popover>
  )
}

export default LiveSettingsButton
